// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { FontRow } from '../typings';

const data = [
  {
    "id": "blockUpper",
    "name": "Block Upper",
    "props": {
      "assetId": "block",
      "fontFamily": "Block",
      "forceUpperCase": true
    }
  },
  {
    "id": "block",
    "name": "Block Upper and Lower",
    "props": {
      "assetId": "block",
      "fontFamily": "Block",
      "forceUpperCase": false
    }
  },
  {
    "id": "script",
    "name": "Script",
    "props": {
      "assetId": "script",
      "fontFamily": "Script",
      "forceUpperCase": false
    }
  },
  {
    "id": "underdog",
    "name": "Underdog All Star",
    "props": {
      "assetId": "underdog",
      "fontFamily": "UnderdogAllStar",
      "forceUpperCase": true
    }
  },
  {
    "id": "gobold",
    "name": "Gobold",
    "props": {
      "assetId": "gobold",
      "fontFamily": "Gobold",
      "forceUpperCase": true
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<FontRow[]>> = data;

export type Font = typeof typed[number];

export const FONT_INDEX_KEY = "id";
export type FontIndexKey = "id";
export type FontId = Font["id"];

let i = 0;
export const FONT_DICT = {
  "blockUpper": typed[i++],
  "block": typed[i++],
  "script": typed[i++],
  "underdog": typed[i++],
  "gobold": typed[i++]
} as const;

export { typed as FONT };
