import { pickBy, identity } from '@technically/lodash'

import getAsset from '~p/getAsset'

import * as batSvgConfig from '../renderSvg/batSvgConfig'
import * as endBrandSvgConfig from '../renderSvg/endBrandSvgConfig'
import { expandValues } from '../common/dataUtils'
import { CUPPED_END_DICT, KNOB_STYLE_DICT } from '../common/sheets'

const getSceneDef = (data) => {
  if (!data.product) {
    return {
      scene: {},
      models: {},
    }
  }

  const { assetId, meshScale } = data.product.model.props
  const woodAssetId = data.product.woodSpecies.props.assetId
  const gripTape = data.options && data.options.gripTape
  const size = { id: '2k', width: 2048, height: 2048 }
  const endBrandLayout = data.decoration.endSection?.endBrandLayout
  const endBrandSize =
    endBrandLayout?.id === '2' ?
      {
        width: 1024,
        height: 684,
      }
    : {
        width: 1024,
        height: 718,
      }
  const decal = data.decoration.knob?.decal

  const cuppedEndMeshMap = Object.keys(CUPPED_END_DICT).reduce((acc, key) => {
    acc[key === 'none' ? key : `end-${key}_${assetId}`] =
      CUPPED_END_DICT[key].id === (data.options?.cuppedEnd?.id ?? 'none')
    return acc
  }, {})

  const knobDecalMeshMap = Object.keys(KNOB_STYLE_DICT).reduce((acc, key) => {
    const isMatchingKnobStyle =
      KNOB_STYLE_DICT[key].id === data.options?.knobStyle?.id
    const shouldShow = isMatchingKnobStyle && !!decal

    acc[`puck-${key}-decalBase_${assetId}`] = shouldShow
    acc[`puck-${key}-decalBubble_${assetId}`] = shouldShow

    return acc
  }, {})

  const puckKnobMeshMap = Object.keys(KNOB_STYLE_DICT).reduce((acc, key) => {
    acc[`puck-${key}_${assetId}`] =
      KNOB_STYLE_DICT[key].id === data.options?.knobStyle?.id
    return acc
  }, {})

  const safeData = expandValues(data)
  return {
    scene: {
      hdr: getAsset('studioSpecularHDR.dds'),
    },
    models: pickBy(
      {
        bat: {
          meshes: getAsset(`bats/${assetId}/${assetId}.babylon`),
          scale: meshScale,
          meshVisibility: {
            base: true,
            [`end-regular_${assetId}`]: cuppedEndMeshMap.none,
            ...cuppedEndMeshMap,
            ...puckKnobMeshMap,
          },
          textures: {
            normal: getAsset(
              `bats/${assetId}/${assetId}_${woodAssetId}_normals.png`,
            ),
            diffuse: batSvgConfig.getDiffuseDef(safeData, size),
            roughness: batSvgConfig.getRoughnessDef(safeData, size),
          },
        },
        endBrand: endBrandLayout && {
          meshes: getAsset(`bats/${assetId}/${assetId}-endBrand.babylon`),
          scale: meshScale,
          textures: {
            diffuse: endBrandSvgConfig.getDiffuseDef(safeData, endBrandSize),
            roughness: endBrandSvgConfig.getRoughnessDef(
              safeData,
              endBrandSize,
            ),
            normal: endBrandSvgConfig.getNormalDef(safeData, endBrandSize),
          },
        },
        decalBase: !!decal && {
          meshes: getAsset(`bats/${assetId}/${assetId}-decalBase.babylon`),
          scale: meshScale,
          meshVisibility: {
            ...knobDecalMeshMap,
          },
          textures: {
            diffuse: getAsset(`textures/decals/${decal.id}.png`),
          },
        },
        decalBubble: !!decal && {
          meshes: getAsset(`bats/${assetId}/${assetId}-decalBubble.babylon`),
          scale: meshScale,
          meshVisibility: {
            ...knobDecalMeshMap,
          },
        },
        gripTape: gripTape && {
          meshes: getAsset(`bats/${assetId}/${assetId}-grip.babylon`),
          scale: meshScale,
          textures: {
            diffuse: getAsset(`bats/gripDiffuse/${gripTape.id}.png`),
            roughness: getAsset(`bats/gripMetallicRoughness.png`),
          },
        },
      },
      identity,
    ),
  }
}

export default getSceneDef
