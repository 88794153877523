import { loadRecipe, setOriginValues } from '~p/client/common/actions'
import configureRouter from '~p/client/configureRouter'
import configureStore from '~p/client/configureStore'

import * as meta from '../common/meta'
import { PROP_DEF_DICT, PROP_DEFS } from '../common/sheets'
import controlTree from './controlTree'
import updater from './updater'

const store = configureStore({
  updater,
  initialState: {
    sheets: { propDefs: PROP_DEF_DICT },
    sheetLists: { propDefs: PROP_DEFS },
  },
  controlTree,
})

const routes = ['/sku/:sku', '/design/:recipeId(/:status)', '/review/:recipeId']

const { initialMatches } = configureRouter(controlTree, store, routes)

if (initialMatches) {
  const matches = initialMatches
  const { sku, recipeId } = matches

  if (recipeId) {
    store.dispatch(loadRecipe(controlTree, recipeId))
  } else if (sku) {
    const { model, woodSpecies, length } = meta.getSkuValues(sku)
    const ctValues = {
      'product.model': model.id,
      'filter.category': model.props.categoryId,
      'filter.series': model.props.series,
      'product.woodSpecies': woodSpecies.id,
      'product.length': length.id,
    }
    store.dispatch(controlTree.setValues(ctValues))
    store.dispatch(setOriginValues(controlTree))
  }
} else {
  store.dispatch(setOriginValues(controlTree))
}

export default store
