import { compact, each, transform, uniq } from '@technically/lodash'

import getAsset from '~p/getAsset'

import {
  hideChildren,
  expandQuery,
  fillDeep,
  createSvgContainer,
  createImage,
  updateImage,
  updateSvg,
  updateText,
  createSvgDrawing,
} from '~p/client/renderSvg/svgUtils'
import { toNumberOrDie } from '@orangelv/utils'

import { FONT_DICT, SURFACE_MATERIAL_DICT } from '../common/sheets'

const getTextDef = (textValue, fontValue) => {
  if (textValue == null || fontValue == null) {
    return {
      text: undefined,
      font: null,
    }
  }
  return {
    text: fontValue.props.forceUpperCase ? textValue.toUpperCase() : textValue,
    font: fontValue.props.fontFamily,
  }
}

const getFontUrls = (fontList) =>
  transform(
    uniq(compact(fontList)),
    (result, font) => {
      result[font.props.assetId] = getAsset(`fonts/${font.props.assetId}.css`)
    },
    {},
  )

const getSurfaceImage = (surfaceArea, fallbackValue) => {
  if (surfaceArea == null) {
    return null
  }
  if (surfaceArea.flame) {
    return surfaceArea.flame.id
  }
  if (surfaceArea.type.id === 'natural') {
    return surfaceArea.type.id
  }

  return fallbackValue
}

const getSurfaceColor = (surfaceArea) => {
  if (surfaceArea == null || surfaceArea.color == null) {
    return null
  }
  return surfaceArea.color.props.hex
}

const getSurfaceOpacity = (surfaceArea) => {
  if (surfaceArea == null || surfaceArea.style == null) {
    return 1
  }

  if (surfaceArea.style.id === 'stained') {
    return 0.7
  }
  if (surfaceArea.style.id === 'painted') {
    return 0.9
  }
  return 1
}

const getSurfaceMetallicRoughness = (
  materialId,
  fallbackMaterial = SURFACE_MATERIAL_DICT.unfinished,
) => {
  if (!materialId) return fallbackMaterial.props.metallicRoughness

  const mat = SURFACE_MATERIAL_DICT[materialId]
  if (!mat) return fallbackMaterial.props.metallicRoughness

  return mat.props.metallicRoughness
}

const getAssetDef = (data, size, isDiffuse) => {
  const modelId = data.product.model.props.assetId
  const woodAssetId = data.product.woodSpecies.props.assetId
  const flamePrefix = `bats/${modelId}/woodTexture/${modelId}_${woodAssetId}`

  let barrelImage = getSurfaceImage(data.colors.barrel, null)
  let handleImage = getSurfaceImage(data.colors.handle, null)
  let baseImage = handleImage || barrelImage || 'natural'

  if (baseImage === barrelImage) {
    barrelImage = null
  }

  if (handleImage === 'tar' || barrelImage === 'tar') {
    baseImage = 'burnt'
    barrelImage = 'natural'
    handleImage = 'natural'
  }

  const middleSection = data.decoration.middleSection || {}
  const endSection = data.decoration.endSection || {}
  const knob = data.decoration.knob || {}

  return {
    svg: getAsset(`bats/${modelId}/${modelId}.svg`),
    images: {
      base:
        isDiffuse && baseImage ?
          getAsset(`${flamePrefix}_${baseImage}.png`)
        : null,
      barrel:
        isDiffuse && barrelImage ?
          getAsset(`${flamePrefix}_${barrelImage}.png`)
        : null,
      handle:
        isDiffuse && handleImage ?
          getAsset(`${flamePrefix}_${handleImage}.png`)
        : null,
      middleSection:
        middleSection.customLogo ?
          `/api/images/${middleSection.customLogo.id}`
        : null,
      endSection:
        endSection.customLogo ?
          `/api/images/${endSection.customLogo.id}`
        : null,
      hologram: getAsset(`textures/hologram.png`),
      knobDecal:
        knob.decal ? getAsset(`textures/decals/${knob.decal.id}.png`) : null,
    },
    svgs: {
      logo: getAsset(`ovalLogo.svg`),
      knobLogo: getAsset(`ovalLogo.svg`),
      stripe: getAsset(`stripe.svg`),
      middleSection:
        middleSection.stockLogo ?
          getAsset(`stockLogos/${middleSection.stockLogo.id}.svg`)
        : null,
      endSection:
        endSection.stockLogo ?
          getAsset(`stockLogos/${endSection.stockLogo.id}.svg`)
        : null,
    },
    fonts: getFontUrls([
      endSection.upperFont,
      endSection.middleFont,
      endSection.lowerFont,
      data.decoration.knob.type.id === 'text' ? FONT_DICT.block : undefined,
    ]),
  }
}

const getDiffuseFillData = (data) => {
  const middleSection = data.decoration.middleSection || {}
  const endSection = data.decoration.endSection || {}
  const knob = data.decoration.knob || {}

  return {
    handle: getSurfaceColor(data.colors.handle),
    barrel: getSurfaceColor(data.colors.barrel),
    handleOpacity: getSurfaceOpacity(data.colors.handle),
    barrelOpacity: getSurfaceOpacity(data.colors.barrel),
    stripe:
      data.colors.stripe && data.colors.stripe.color ?
        data.colors.stripe.color.props.hex
      : null,
    stripeTexture:
      data.colors.stripe?.color ?
        data.colors.stripe.color.props.textureId
      : null,
    logo: data.colors.ovalLogo ? data.colors.ovalLogo.props.logo.hex : null,
    logoTexture:
      data.colors.ovalLogo ? data.colors.ovalLogo.props.logo.textureId : null,
    middleSection: middleSection.color ? middleSection.color.props.hex : null,
    endSection: endSection.color ? endSection.color.props.hex : null,
    knob: knob.color ? knob.color.props.hex : null,
    knobLogo: knob.color ? knob.color.props.hex : null,
    endCuppedCircle: 'transparent',
  }
}

const getMetallicRoughnessFillData = (data) => {
  const middleSection = data.decoration.middleSection || {}
  const endSection = data.decoration.endSection || {}
  const knob = data.decoration.knob || {}

  return {
    handle: getSurfaceMetallicRoughness(
      data.colors.handle &&
        data.colors.handle.finish &&
        data.colors.handle.finish.props.surfaceMaterialId,
    ),
    barrel: getSurfaceMetallicRoughness(
      data.colors.barrel &&
        data.colors.barrel.finish &&
        data.colors.barrel.finish.props.surfaceMaterialId,
    ),
    handleOpacity: 1,
    barrelOpacity: 1,
    stripe:
      data.colors.stripe && data.colors.stripe.color ?
        getSurfaceMetallicRoughness(
          data.colors.stripe.color.props.surfaceMaterialId,
        )
      : null,

    logo:
      data.colors.ovalLogo ?
        getSurfaceMetallicRoughness(
          data.colors.ovalLogo.props.logo.surfaceMaterialId,
        )
      : null,
    logoTexture: null,
    middleSection:
      middleSection.color && middleSection.color.props.isMetallic ?
        getSurfaceMetallicRoughness('foilSticker')
      : null,
    endSection:
      endSection.color && endSection.color.props.isMetallic ?
        getSurfaceMetallicRoughness('foilSticker')
      : null,
    knob:
      knob.color && knob.color.props.isMetallic ?
        getSurfaceMetallicRoughness('foilSticker')
      : null,
    knobLogo:
      knob.color && knob.color.props.isMetallic ?
        getSurfaceMetallicRoughness('foilSticker')
      : null,
    endCuppedCircle: '#FF4C00',
  }
}

const getTextDimensions = (textElement, svgDrawing) => {
  // Using a temporary svg for text measurement
  // Reason: text dimensions are not correctly detected by svg.js
  // in the initial drawing when using custom fonts
  // https://github.com/svgdotjs/svg.js/issues/1267
  const tempContainer = document.createElement('div')
  tempContainer.style.position = 'absolute'
  tempContainer.style.visibility = 'hidden'
  document.body.appendChild(tempContainer)

  const tempSvg = SVG(tempContainer).size(500, 500)
  const defs = svgDrawing.defs()
  if (defs) {
    tempSvg.defs().svg(defs.svg())
  }

  const tempText = tempSvg
    .text((add) => {
      add.tspan(textElement.text())
    })
    .font({
      family: textElement.attr('font-family'),
      size: textElement.attr('font-size'),
      weight: textElement.attr('font-weight'),
    })

  const isRotated = textElement.transform().rotation !== 0

  const width = isRotated ? tempText.bbox().height : tempText.bbox().width
  const height = isRotated ? tempText.bbox().width : tempText.bbox().height

  // Clean up
  tempContainer.remove()

  return { width, height }
}

const justifySiblings = (elements, boundingElement, drawing) => {
  const ELEMENT_SPACING = 15

  // Calculate total width and element widths in one pass
  const { totalWidth, elementWidths } = elements.reduce(
    (acc, el) => {
      const { width } = getTextDimensions(el, drawing)
      return {
        totalWidth: acc.totalWidth + width + ELEMENT_SPACING,
        elementWidths: [...acc.elementWidths, width],
      }
    },
    { totalWidth: -ELEMENT_SPACING, elementWidths: [] },
  )

  const startX = boundingElement.cx() - totalWidth / 2

  for (const [index, element] of elements.entries()) {
    const width = elementWidths[index]

    // Calculate the cumulative width of all previous elements plus spacing
    const previousWidthsAndSpacing = elementWidths
      .slice(0, index)
      .reduce((sum, w) => sum + w + ELEMENT_SPACING, 0)

    // Calculate the x position for the current element
    const xPosition = startX + previousWidthsAndSpacing + width / 2

    element
      .attr({
        'text-anchor': 'middle',
        'dominant-baseline': 'middle',
      })
      .transform({
        x: xPosition,
        y: boundingElement.cy(),
      })
  }
}

const scaleAndCenterText = (textElement, boundingElement, svgDrawing) => {
  const boundaryBox = boundingElement.bbox()

  const { width: textWidth, height: textHeight } = getTextDimensions(
    textElement,
    svgDrawing,
  )

  const scaleX = boundaryBox.width / textWidth
  const scaleY = boundaryBox.height / textHeight
  const scale = Math.min(scaleX, scaleY, 1)

  textElement
    .transform({
      scale: scale,
    })
    .transform({
      x: boundaryBox.cx,
      y: boundaryBox.cy,
    })
    .transform({})
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle')
}

const convertInchNotation = (input) => {
  if (typeof input !== 'string' || !input.endsWith('"')) return input
  try {
    const numberPart = toNumberOrDie(input.slice(0, -1))

    return `${numberPart} in.`
  } catch {
    return input
  }
}

const getTextData = (data) => {
  const endSection = data.decoration.endSection || {}
  const knob = data.decoration.knob || {}
  const isKnobTextAvailable = 'text' in knob

  return {
    knob: getTextDef(
      knob.text ? knob.text.toUpperCase() : null,
      FONT_DICT.block,
    ),
    length: getTextDef(
      isKnobTextAvailable ? data.product.length.name.toUpperCase() : null,
      FONT_DICT.block,
    ),
    upper: getTextDef(endSection.upperText, endSection.upperFont),
    middle: getTextDef(endSection.middleText, endSection.middleFont),
    lower: getTextDef(endSection.lowerText, endSection.lowerFont),
  }
}

const getData = (data, type) => ({
  stripeType:
    data.colors.stripe && data.colors.stripe.size ?
      data.colors.stripe.size.props.assetId
    : null,
  fill:
    type === 'diffuse' ?
      getDiffuseFillData(data)
    : getMetallicRoughnessFillData(data),
  text: getTextData(data),
})

const getBaseSvg = (svg) => {
  const drawing = createSvgDrawing(svg)

  // IMAGES
  drawing.select(expandQuery('$uv')).each((i, children) => {
    children[i].remove()
  })
  updateImage(drawing, '$wood $base', '', null)
  updateImage(drawing, '$wood $barrel', '', null)
  updateImage(drawing, '$wood $handle', '', null)
  createImage(drawing, '$placement-middleSection $box')
  createImage(drawing, '$placement-endSection $box')

  // SVG
  createSvgContainer(drawing, '$placement-logo $box')
  createSvgContainer(drawing, '$placement-middleSection $box')
  createSvgContainer(drawing, '$placement-endSection $box')
  createSvgContainer(drawing, '$knob-logo $box')
  createSvgContainer(drawing, '$knob-extended-logo $box')
  createSvgContainer(drawing, '$knob-standard-logo $box')
  createSvgContainer(drawing, '$stripe-narrow $box')

  // TEXT
  drawing.select(expandQuery('text')).each((i, children) => {
    const text = children[i]
    const bbox = text.bbox()
    text.attr('text-anchor', 'middle')
    text.x(text.x() + bbox.width / 2)
    text.plain('')
  })

  // BOXES
  drawing.select(expandQuery('$placements rect')).attr('display', 'none')

  return drawing
}

const getDynamicSvg = (data, size, baseSvg, images, svgs, fonts) => {
  const drawing = baseSvg
  drawing.size(size.width, size.height)
  drawing.defs().clear()

  //  IMAGES
  updateImage(drawing, '$wood $base', images.base, null)
  updateImage(drawing, '$wood $barrel', images.barrel, null)
  updateImage(drawing, '$wood $handle', images.handle, null)
  updateImage(
    drawing,
    '$placement-middleSection image',
    images.middleSection,
    data.fill.middleSection,
  )
  updateImage(
    drawing,
    '$placement-endSection image',
    images.endSection,
    data.fill.endSection,
  )

  // SVGS
  updateSvg(drawing, '$placement-logo $svg-container', svgs.logo, null)
  updateSvg(
    drawing,
    '$placement-middleSection $svg-container',
    svgs.middleSection,
    data.fill.middleSection,
  )
  updateSvg(
    drawing,
    '$placement-endSection $svg-container',
    svgs.endSection,
    data.fill.endSection,
  )
  updateSvg(
    drawing,
    [
      '$knob-logo $svg-container',
      '$knob-standard-logo $svg-container',
      '$knob-extended-logo $svg-container',
    ].toString(),
    svgs.knobLogo,
    null,
  )

  updateSvg(
    drawing,
    '$stripe-narrow $svg-container',
    svgs.stripe,
    data.fill.stripe,
  )

  // TEXT
  const styleNode = drawing
    .defs()
    .node.appendChild(document.createElement('style'))
  each(fonts, (font) => styleNode.appendChild(document.createTextNode(font)))

  updateText(
    drawing,
    '$text-knob text',
    data.text.knob.text,
    data.text.knob.font,
    data.fill.knob,
  )
  updateText(
    drawing,
    '$text-knob-length text',
    data.text.length.text,
    data.text.length.font,
    data.fill.knob,
  )
  updateText(
    drawing,
    '$text-end-upper text',
    data.text.upper.text,
    data.text.upper.font,
    data.fill.endSection,
  )
  updateText(
    drawing,
    '$text-end-middle text',
    data.text.middle.text,
    data.text.middle.font,
    data.fill.endSection,
  )
  updateText(
    drawing,
    '$text-end-lower text',
    data.text.lower.text,
    data.text.lower.font,
    data.fill.endSection,
  )

  // FILL
  drawing
    .select(
      expandQuery('$areas $handle, $areas $knob-center, $areas $knob-body'),
    )
    .attr('display', data.fill.handle ? 'block' : 'none')
    .attr('opacity', data.fill.handleOpacity)
    .fill(data.fill.handle || undefined)

  drawing
    .select(
      expandQuery(
        [
          '$areas $barrel',
          '$areas $end-regular',
          '$areas $end-0_5inch',
          '$areas $end-1inch',
          '$areas $end-1_25inch',
        ].toString(),
      ),
    )
    .attr('display', data.fill.barrel ? 'block' : 'none')
    .attr('opacity', data.fill.barrelOpacity)
    .fill(data.fill.barrel || undefined)

  drawing
    .select(
      expandQuery(
        [
          '$areas $end-0_5inch_inside',
          '$areas $end-1inch_inside',
          '$areas $end-1_25inch_inside',
        ].toString(),
      ),
    )
    .fill(data.fill.endCuppedCircle)

  drawing
    .select(expandQuery('$areas $stripe-wide'))
    .attr('display', data.stripeType === 'wide' ? 'block' : 'none')
    .fill(data.fill.stripe || undefined)

  const stripeTexture = data.fill.stripeTexture

  hideChildren(drawing, '$stripe-narrow $stripeTexture')

  drawing
    .select(expandQuery('$stripe-narrow $stripe'))
    .fill(data.fill.stripe || 'none')

  if (stripeTexture) {
    drawing
      .select(expandQuery(`$stripe-narrow $stripeTexture $${stripeTexture}`))
      .attr('display', 'block')

    if (stripeTexture === 'hologram') {
      updateImage(drawing, '$stripe-narrow image', images.hologram, null)
    }
  }

  drawing
    .select(expandQuery('$placement-logo $logo'))
    .fill(data.fill.logo || 'none')

  hideChildren(drawing, '$placement-logo $logoTexture')

  const logoTexture = data.fill.logoTexture
  if (logoTexture) {
    drawing
      .select(expandQuery(`$placement-logo $logoTexture $${logoTexture}`))
      .attr('display', 'block')

    if (logoTexture === 'hologram') {
      updateImage(
        drawing,
        '$placement-logo $hologram image',
        images.hologram,
        null,
      )
    }
  }

  fillDeep(
    drawing
      .select(
        expandQuery(
          [
            '$knob-logo $fillColor',
            '$knob-standard-logo $fillColor',
            '$knob-extended-logo $fillColor',
          ].toString(),
        ),
      )
      .attr('display', 'block'),
    'transparent',
  )

  hideChildren(drawing, '$knob-standard-logo $logoTexture')
  hideChildren(drawing, '$knob-extended-logo $logoTexture')

  fillDeep(
    drawing
      .select(
        expandQuery(
          [
            '$knob-logo $logo',
            '$knob-standard-logo $logo',
            '$knob-extended-logo $logo',
          ].toString(),
        ),
      )
      .attr('display', data.fill.knobLogo ? 'block' : 'none'),
    data.fill.knobLogo || undefined,
  )

  drawing
    .select(expandQuery('$graphics $rawlingsPro'))
    .fill(data.fill.endSection || undefined)

  return drawing
}

const instructions = {
  getBaseSvg,
  getDynamicSvg,
}

export const getDiffuseDef = (values, size) => ({
  name: 'bat-diffuse',
  assets: getAssetDef(values, size, true),
  data: getData(values, 'diffuse'),
  size,
  instructions,
})

export const getRoughnessDef = (values, size) => ({
  name: 'bat-roughness',
  assets: getAssetDef(values, size, false),
  data: getData(values, 'metallicRoughness'),
  size,
  instructions,
})
