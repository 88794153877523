import { FxaaPostProcess, Mesh } from '@babylonjs/core'

import createEngine from '~p/client/render3d/createEngine'
import createScene from '~p/client/render3d/createScene'
import createCamera from '~p/client/render3d/createCamera'
import {
  createBatMaterial,
  createEndBrandMaterial,
  createDecalBaseMaterial,
  createDecalBubbleMaterial,
  createGripTapeMaterial,
} from './materials'

const createSceneContext = (canvas) => {
  const engine = createEngine(canvas)
  const scene = createScene(engine)
  const camera = createCamera(scene)

  const fxaa = new FxaaPostProcess('fxaa', 1.4, camera)
  const rotationMesh = new Mesh('rotation', scene)
  const positionMesh = new Mesh('position', scene)
  positionMesh.parent = rotationMesh

  return {
    engine,
    scene,
    camera,
    materials: {
      bat: createBatMaterial(scene),
      gripTape: createGripTapeMaterial(scene),
      endBrand: createEndBrandMaterial(scene),
      decalBase: createDecalBaseMaterial(scene),
      decalBubble: createDecalBubbleMaterial(scene),
    },
    rotationMesh,
    positionMesh,
  }
}

export default createSceneContext
