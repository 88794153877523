import {
  Scene,
  Color4,
  HemisphericLight,
  Vector3,
  ImageProcessingConfiguration,
} from '@babylonjs/core'

const createScene = (engine) => {
  const scene = new Scene(engine)
  scene.clearColor = new Color4(0, 0, 0, 0)

  scene.imageProcessingConfiguration.contrast = 1.4
  scene.imageProcessingConfiguration.exposure = 1
  scene.imageProcessingConfiguration.toneMappingEnabled = true
  scene.imageProcessingConfiguration.toneMappingType =
    ImageProcessingConfiguration.TONEMAPPING_KHR_PBR_NEUTRAL

  const light = new HemisphericLight('light1', new Vector3(0, 1, 0), scene)
  light.intensity = 1

  return scene
}

export default createScene
