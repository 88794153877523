import Bluebird from 'bluebird'
import { Engine, RawTexture, Texture } from '@babylonjs/core'

const DEFAULT_PARAMS = {
  displacementStrength: 1.5,
  sampleRadius: 2,
  normalStrength: 1.4,
  heightThreshold: 0.95,
}

const createCanvas = (width, height) => {
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  return canvas
}

const getImageData = (canvas, width, height, fallbackValue = 255) => {
  try {
    return canvas.getContext('2d').getImageData(0, 0, width, height).data
  } catch (error) {
    console.error('Error accessing image data:', error)
    const fallback = new Uint8ClampedArray(width * height * 4).fill(
      fallbackValue,
    )
    if (fallbackValue === 128) {
      fallback.forEach((_, i) => {
        if ((i + 1) % 4 === 0) fallback[i] = 255
      })
    }
    return fallback
  }
}

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.onload = () => resolve(img)
    img.onerror = () => reject(new Error(`Failed to load image: ${src}`))
    img.onabort = () => reject(new Error('Image loading was aborted'))
    img.src = src
  })
}

const calculateDimensions = (
  imgWidth,
  imgHeight,
  targetDimension,
  isPortrait,
) => {
  const aspectRatio = imgWidth / imgHeight
  if (isPortrait) {
    return {
      width: Math.round(targetDimension * aspectRatio),
      height: targetDimension,
    }
  }

  return {
    width: targetDimension,
    height: Math.round(targetDimension / aspectRatio),
  }
}

const getHeightMapData = (img, width, height) => {
  const canvas = createCanvas(width, height)
  const ctx = canvas.getContext('2d')

  ctx.fillStyle = 'white'
  ctx.fillRect(0, 0, width, height)

  ctx.save()
  ctx.scale(1, -1)
  ctx.translate(0, -height)
  ctx.drawImage(img, 0, 0, width, height)
  ctx.restore()

  return getImageData(canvas, width, height)
}

const getBaseNormalMapData = (baseNormalImg, width, height) => {
  const canvas = createCanvas(width, height)
  const ctx = canvas.getContext('2d')
  ctx.drawImage(baseNormalImg, 0, 0, width, height)

  return getImageData(canvas, width, height, 128)
}

const calculateNormal = (x, y, width, height, heightData, params) => {
  const { displacementStrength, sampleRadius } = params
  const index = (y * width + x) * 4
  const heightValue = heightData[index] / 255

  const left =
    x > sampleRadius ?
      heightData[(y * width + (x - sampleRadius)) * 4] / 255
    : heightValue
  const right =
    x < width - sampleRadius ?
      heightData[(y * width + (x + sampleRadius)) * 4] / 255
    : heightValue
  const top =
    y > sampleRadius ?
      heightData[((y - sampleRadius) * width + x) * 4] / 255
    : heightValue
  const bottom =
    y < height - sampleRadius ?
      heightData[((y + sampleRadius) * width + x) * 4] / 255
    : heightValue

  const dx = (right - left) * displacementStrength
  const dy = (bottom - top) * displacementStrength
  const dz = 1.0 - Math.min(1, (dx * dx + dy * dy) * 0.5)

  return { dx, dy, dz, heightValue }
}

const blendNormals = (baseNormal, calculatedNormal, heightValue, params) => {
  const { normalStrength } = params
  const { dx, dy, dz } = calculatedNormal

  const baseX = ((baseNormal[0] / 255) * 2 - 1) * normalStrength
  const baseY = ((baseNormal[1] / 255) * 2 - 1) * normalStrength
  const baseZ = baseNormal[2] / 255

  const blendFactor = 1 - heightValue
  const blendedX = baseX - dx * blendFactor * 2
  const blendedY = baseY - dy * blendFactor * 2
  const blendedZ = baseZ * (1 - blendFactor * 0.3) + dz * blendFactor

  const length = Math.sqrt(
    blendedX * blendedX + blendedY * blendedY + blendedZ * blendedZ,
  )

  return {
    x: 255 - ((blendedX / length) * 0.5 + 0.5) * 255,
    y: 255 - ((blendedY / length) * 0.5 + 0.5) * 255,
    z: (blendedZ / length) * 255,
  }
}

export const svgToNormalMap = (
  svgString,
  baseNormalMapUrl,
  targetDimension,
  isPortrait = false,
  params = DEFAULT_PARAMS,
) => {
  return new Bluebird(async (resolve, reject, onCancel) => {
    let images
    try {
      const [svgImage, baseNormalImage] = await Promise.all([
        loadImage(
          'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgString),
        ),
        loadImage(baseNormalMapUrl, true),
      ])
      images = { svgImage, baseNormalImage }
    } catch (error) {
      reject(error)
      return
    }

    onCancel(() => {
      if (images) {
        images.svgImage.src = ''
        images.baseNormalImage.src = ''
      }
    })

    const { width, height } = calculateDimensions(
      images.svgImage.width,
      images.svgImage.height,
      targetDimension,
      isPortrait,
    )

    const heightData = getHeightMapData(images.svgImage, width, height)
    const baseNormalData = getBaseNormalMapData(
      images.baseNormalImage,
      width,
      height,
    )

    const combinedMap = new Uint8Array(width * height * 4)

    for (let y = 0; y < height; y += 1) {
      for (let x = 0; x < width; x += 1) {
        const index = (y * width + x) * 4
        const heightValue = heightData[index] / 255
        combinedMap[index + 3] = heightData[index]

        if (heightValue < params.heightThreshold) {
          const calculatedNormal = calculateNormal(
            x,
            y,
            width,
            height,
            heightData,
            params,
          )
          const baseNormal = baseNormalData.slice(index, index + 4)
          const blendedNormal = blendNormals(
            baseNormal,
            calculatedNormal,
            heightValue,
            params,
          )
          combinedMap[index] = blendedNormal.x
          combinedMap[index + 1] = blendedNormal.y
          combinedMap[index + 2] = blendedNormal.z
        } else {
          combinedMap[index] = baseNormalData[index]
          combinedMap[index + 1] = baseNormalData[index + 1]
          combinedMap[index + 2] = baseNormalData[index + 2]
        }
      }
    }

    resolve(
      new RawTexture(
        combinedMap,
        width,
        height,
        Engine.TEXTUREFORMAT_RGBA,
        null,
        true,
        false,
        Texture.TRILINEAR_SAMPLINGMODE,
      ),
    )
  })
}
