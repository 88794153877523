import { set, filter, map, reduce, template, some } from '@technically/lodash'
import { withProps } from 'recompose'

import { getSkuLabel } from '../common/meta'

import {
  createControlTree,
  Select,
  FileUpload,
  Text,
} from '~p/client/control-tree'

import {
  PROP_DEF_DICT,
  CATEGORY,
  LENGTH,
  OVAL_LOGO_COLOR,
  WOOD_SPECIES,
  MODEL,
  SURFACE_TYPE,
  STRIPE_COLOR,
  DECO_TYPE,
  DECO_TYPE_DICT,
  STOCK_LOGO,
  STAMP_COLOR,
  SURFACE_STYLE_DICT,
  SURFACE_COLOR_DICT,
  CURRENCY_DICT,
  GROUP,
  SERIES,
  WEIGHT,
  COLOR_COMBO,
  SURFACE_FINISH,
  STRIPE_SIZE,
  FONT,
  KNOB_TEXT_COLOR,
  GRIP_TAPE,
  CUPPED_END,
  YES_OR_NO,
  KNOB_STYLE,
  END_BRAND_LAYOUT,
  KNOB_DECO_TYPE,
  KNOB_DECAL,
  KNOB_DECO_TYPE_DICT,
} from '../common/sheets'

const isPropDefAvailable = (x) => !(x === 'FALSE' || x === undefined)

const getPropDef = (id) => {
  const propDefId = id
  const propDef = PROP_DEF_DICT[propDefId]
  if (propDef == null) {
    throw new Error(`Failed to find propDef: ${propDefId}`)
  }
  return propDef
}

const boilerplate = (propDef) => {
  const tileImageTemplate =
    propDef.tileImageProps && template(propDef.tileImageProps)

  return {
    label: propDef.name,
    description: propDef.description,
    defaultValue:
      'defaultValueId' in propDef && propDef.defaultValueId ?
        propDef.defaultValueId
      : null,
    isRequired: !propDef.isOptional,
    extraCost: propDef.extraCost,
    tileType: propDef.tileType,
    viewName: propDef.viewNames[0],
    tileImageTemplate: tileImageTemplate ? () => tileImageTemplate : null,
  }
}

const requiredWhenSingleOption = (options) =>
  // Sets node as required when there's only single option available. This is used for wizard to skip nodes.
  // By default wizard nodes are NOT required and, if there's only single option there, it will count as two options because the actual option + nothing selected (null).
  // In case like that, we set the node to required which will kick in the code that skips this node. Somewhat a hack.
  options.length === 1

const getCategoryOptions = (group) =>
  filter(CATEGORY, (category) => category.props.groupId === group.id)

const getOvalLogoColor = (category) => {
  const availability = PROP_DEF_DICT.colors_ovalLogo.availability[category.id]

  if (!isPropDefAvailable(availability)) {
    return []
  }

  return OVAL_LOGO_COLOR.filter(
    (ovalLogoColor) => ovalLogoColor.subsets.categoryId[category.id],
  ).map((ovalLogoColor) => {
    const { logo } = ovalLogoColor.props
    const hasTileImage =
      logo.surfaceMaterialId === 'foilSticker' || logo.textureId !== undefined
    let additionalProps = {}

    if (hasTileImage) {
      additionalProps.tileImageSrc = `thumbnails/${ovalLogoColor.id}.png`
    } else {
      additionalProps.hex = logo.hex
    }

    return {
      ...ovalLogoColor,
      props: {
        ...ovalLogoColor.props,
        ...additionalProps,
      },
    }
  })
}

const getEndSectionColor = () => {
  return STAMP_COLOR.map((color) => {
    if (color.props.isMetallic) {
      color.props.tileImageSrc = `thumbnails/${color.id}Foil.png`
    }

    return color
  })
}

const getModelOptions = (category) =>
  MODEL.filter((x) => x.props.categoryId === category?.id && x.props.isEnabled)

const getSurfaceTypeOptions = (category) =>
  map(
    filter(
      SURFACE_TYPE,
      (surfaceType) => surfaceType.subsets.categoryId[category.id],
    ),
    (surfaceType) => {
      const { surfaceStyleId, surfaceColorId, surfaceFlameId } =
        surfaceType.props

      if (surfaceColorId) {
        return set(
          surfaceType,
          'props.hex',
          SURFACE_COLOR_DICT[surfaceColorId].props.hex,
        )
      }

      let tileImageSrc

      if (surfaceStyleId === 'natural') {
        tileImageSrc = `surfaceStyles/natural.png`
      } else if (surfaceStyleId === 'flame') {
        tileImageSrc = `flames/${surfaceFlameId}.png`
      }

      return set(surfaceType, 'props.tileImageSrc', tileImageSrc)
    },
  )

const getStripeColorOptions = (stripeSize) =>
  map(
    filter(
      STRIPE_COLOR,
      (stripeColor) => stripeColor.subsets.stripeSize[stripeSize.id],
    ),
    (stripeColor) => {
      const hasTileImage = stripeColor.props.surfaceMaterialId === 'foilSticker'

      if (hasTileImage) {
        return set(
          stripeColor,
          'props.tileImageSrc',
          `thumbnails/${stripeColor.id}.png`,
        )
      }

      return stripeColor
    },
  )

const decoSectionCommon = (section) => {
  const propDefType = getPropDef(`decoration_${section}Section_type`)
  const propDefCustomLogo = getPropDef(
    `decoration_${section}Section_customLogo`,
  )
  const propDefStockLogo = getPropDef(`decoration_${section}Section_stockLogo`)
  const propDefColor = getPropDef(`decoration_${section}Section_color`)
  return {
    type: Select({
      ...boilerplate(propDefType),
      dependencies: ['filter.category'],
      isAvailable: (category) =>
        !!category && isPropDefAvailable(propDefType.availability[category.id]),
      options: (category) => {
        const avail = propDefType.availability[category.id]
        if (!avail) return []
        if (avail === 'TRUE') return DECO_TYPE
        return map(avail.split(','), (x) => DECO_TYPE_DICT[x.trim()])
      },
    }),

    customLogo: FileUpload({
      ...boilerplate(propDefCustomLogo),
      dependencies: [`decoration.${section}Section.type`],
      isAvailable: (type) => !!type && type.id === 'customLogo',
      defaultValue: {
        id: 'default',
        filename: 'rawlings-custom-pro-shop.png',
      },
    }),

    stockLogo: Select({
      ...boilerplate(propDefStockLogo),
      dependencies: [`decoration.${section}Section.type`],
      isAvailable: (type) => type && type.id === 'stockLogo',
      options: STOCK_LOGO,
    }),

    color: Select({
      ...boilerplate(propDefColor),
      dependencies: ['filter.category', `decoration.${section}Section.type`],
      isAvailable: (category, type) =>
        !!category &&
        isPropDefAvailable(propDefColor.availability[category.id]) &&
        !!type,
      options: getEndSectionColor(),
    }),
  }
}

const controlTree = createControlTree({
  env: {
    currency: Text({
      isPrivate: true,
      value: () => () => CURRENCY_DICT.USD,
    }),
  },

  calc: {
    sku: Text({
      isPrivate: true,
      dependencies: ['product.woodSpecies', 'product.model', 'product.length'],
      isAvailable: (woodSpecies, model, length) =>
        !!woodSpecies && !!model && !!length,
      value: (woodSpecies, model, length) => () =>
        [model.id, woodSpecies.id, length.id].join('-'),
    }),

    skuLabel: Text({
      // This SKU label is used in UI & renderer.
      isPrivate: true,
      dependencies: ['product.model', 'options.knobStyle'],
      isAvailable: (model, knobStyle) => !!model && !!knobStyle,
      value: (model, knobStyle) => () => getSkuLabel(model, knobStyle),
    }),

    price: Text({
      isPrivate: true,
      dependencies: [
        'product.woodSpecies',
        'product.model',
        'colors.barrel.type',
        'colors.handle.type',
        'options.gripTape',
        'options.displayTube',
        'decoration.middleSection.customLogo',
        'decoration.middleSection.stockLogo',
      ],
      value:
        (
          woodSpecies,
          model,
          barrelType,
          handleType,
          gripTape,
          displayTube,
          middleCustomLogo,
          middleStockLogo,
        ) =>
        () => {
          if (!model) return 0
          const basePrice = model.props.basePriceByWoodSpecies[woodSpecies.id]

          const extraCosts = reduce(
            [
              [PROP_DEF_DICT.options_gripTape, gripTape],
              [PROP_DEF_DICT.options_displayTube, displayTube],
              [
                PROP_DEF_DICT.decoration_middleSection_customLogo,
                middleCustomLogo,
              ],
              [
                PROP_DEF_DICT.decoration_middleSection_stockLogo,
                middleStockLogo,
              ],
            ],
            (r, [propDef, x]) =>
              r +
              ((
                !propDef.extraCost ||
                x === null ||
                x === undefined ||
                (propDef.dataType === 'yesOrNo' && x.id === 'no')
              ) ?
                0
              : propDef.extraCost),
            0,
          )
          const styles = map(
            [handleType, barrelType],
            (type) => type && SURFACE_STYLE_DICT[type.props.surfaceStyleId],
          )

          const extraCostsSurfaceStyle = reduce(
            styles,
            (r, x) => r + (x ? x.props.extraCost : 0),
            0,
          )

          return basePrice + extraCosts + extraCostsSurfaceStyle
        },
    }),

    priceWithCurrency: Text({
      isPrivate: true,
      dependencies: ['env.currency', 'calc.price'],
      value: (currency, price) => () =>
        `${currency.props.prefix} ${price.toFixed(2)}`,
    }),
  },

  filter: {
    group: Select({
      ...boilerplate(PROP_DEF_DICT.filter_group),
      options: GROUP.filter((x) => x.isEnabled),
    }),

    category: Select({
      ...boilerplate(PROP_DEF_DICT.filter_category),
      dependencies: ['filter.group'],
      isAvailable: (group) => !!group,
      isRequired: (group) =>
        requiredWhenSingleOption(getCategoryOptions(group)),
      visibleOptions: getCategoryOptions,
      options: CATEGORY,
    }),

    series: Select({
      ...boilerplate(PROP_DEF_DICT.filter_series),
      dependencies: ['filter.category'],
      isAvailable: (category) =>
        !!category &&
        isPropDefAvailable(
          PROP_DEF_DICT.filter_series.availability[category.id],
        ),
      options: SERIES,
    }),
  },

  product: {
    model: Select({
      ...boilerplate(PROP_DEF_DICT.product_model),
      dependencies: ['filter.category'],
      isAvailable: (category) => !!category,
      isRequired: (category) =>
        requiredWhenSingleOption(getModelOptions(category)),
      options: getModelOptions,
    }),

    woodSpecies: Select({
      ...boilerplate(PROP_DEF_DICT.product_woodSpecies),
      dependencies: ['filter.category'],
      isAvailable: (category) => !!category,
      options: WOOD_SPECIES,
    }),

    length: Select({
      ...boilerplate(PROP_DEF_DICT.product_length),
      dependencies: ['filter.category', 'product.model'],
      isAvailable: (category, model) =>
        !!model &&
        !!category &&
        isPropDefAvailable(
          PROP_DEF_DICT.product_length.availability[category.id],
        ),
      visibleOptions: (_, model) => LENGTH.filter((x) => x[model.id]),
      options: LENGTH,
    }),

    weight: Select({
      ...boilerplate(PROP_DEF_DICT.product_weight),
      dependencies: ['filter.category', 'product.model'],
      isAvailable: (category, model) =>
        !!model &&
        !!category &&
        isPropDefAvailable(
          PROP_DEF_DICT.product_weight.availability[category.id],
        ),
      visibleOptions: (category, model) =>
        filter(WEIGHT, (weight) => weight.subsets.modelId[model.id]),
      options: WEIGHT,
    }),
  },

  colors: {
    colorCombo: Select({
      ...boilerplate(PROP_DEF_DICT.colors_colorCombo),
      dependencies: ['filter.category'],
      isAvailable: (category) =>
        !!category &&
        isPropDefAvailable(
          PROP_DEF_DICT.colors_colorCombo.availability[category.id],
        ),
      options: COLOR_COMBO,
    }),

    batColor: Select({
      ...boilerplate(PROP_DEF_DICT.colors_batColor),
      dependencies: ['filter.category'],
      isAvailable: (category) =>
        !!category &&
        isPropDefAvailable(
          PROP_DEF_DICT.colors_batColor.availability[category.id],
        ),
      options: SURFACE_TYPE,
      visibleOptions: getSurfaceTypeOptions,
    }),

    ...['handle', 'barrel'].reduce((r, x) => {
      const propDefType = getPropDef(`colors_${x}_type`)
      const propDefFinish = getPropDef(`colors_${x}_finish`)
      return {
        ...r,
        [x]: {
          type: Select({
            ...boilerplate(propDefType),
            dependencies: ['filter.category'],
            isAvailable: (category) =>
              !!category &&
              isPropDefAvailable(propDefType.availability[category.id]),
            options: SURFACE_TYPE,
            visibleOptions: getSurfaceTypeOptions,
          }),
          finish: Select({
            ...boilerplate(propDefFinish),
            dependencies: ['filter.category', `colors.${x}.type`],
            isAvailable: (_, type) => type != null,
            options: (category) =>
              filter(
                SURFACE_FINISH,
                (surfaceFinish) =>
                  surfaceFinish.subsets.categoryId[category.id],
              ),
          }),
        },
      }
    }, {}),

    stripe: {
      size: Select({
        ...boilerplate(PROP_DEF_DICT.colors_stripe_size),
        dependencies: ['filter.category'],
        isAvailable: (category) => !!category,
        options: STRIPE_SIZE,
        visibleOptions: (category) =>
          filter(
            STRIPE_SIZE,
            (stripeSize) => stripeSize.subsets.categoryId[category.id],
          ),
      }),

      color: Select({
        ...boilerplate(PROP_DEF_DICT.colors_stripe_color),
        dependencies: ['colors.stripe.size'],
        isAvailable: (stripeSize) => stripeSize,
        options: (stripeSize) =>
          filter(
            STRIPE_COLOR,
            (stripeColor) => stripeColor.subsets.stripeSize[stripeSize.id],
          ),
        visibleOptions: getStripeColorOptions,
      }),
    },

    ovalLogo: Select({
      ...boilerplate(PROP_DEF_DICT.colors_ovalLogo),
      dependencies: ['filter.category'],
      isAvailable: (category) =>
        !!category &&
        isPropDefAvailable(
          PROP_DEF_DICT.colors_ovalLogo.availability[category.id],
        ),
      options: OVAL_LOGO_COLOR,
      visibleOptions: getOvalLogoColor,
    }),
  },

  decoration: {
    middleSection: decoSectionCommon('middle'),

    endSection: {
      ...decoSectionCommon('end'),

      ...['upper', 'middle', 'lower'].reduce((r, x) => {
        const propDefText = getPropDef(`decoration_endSection_${x}Text`)
        return {
          ...r,
          [`${x}Text`]: Text({
            ...boilerplate(propDefText),
            dependencies: ['filter.category', 'decoration.endSection.type'],
            isAvailable: (category, type) =>
              !!category &&
              isPropDefAvailable(propDefText.availability[category.id]) &&
              !!type &&
              type.id === 'text',
            maxLength: 20,
          }),
          [`${x}Font`]: Select({
            ...boilerplate(getPropDef(`decoration_endSection_${x}Font`)),
            dependencies: [`decoration.endSection.${x}Text`],
            isAvailable: (text) => text,
            options: FONT,
          }),
        }
      }, {}),

      endBrandText: (() => {
        const propDef = getPropDef(`decoration_endSection_endBrandText`)
        return Text({
          ...boilerplate(propDef),
          dependencies: ['filter.category', 'decoration.endSection.type'],
          isAvailable: (category, type) =>
            !!category &&
            isPropDefAvailable(propDef.availability[category.id]) &&
            !!type &&
            type.id === 'endBrandText',
          maxLength: 20,
        })
      })(),

      endBrandLayout: Select({
        ...boilerplate(getPropDef(`decoration_endSection_endBrandLayout`)),
        dependencies: [`decoration.endSection.type`],
        isAvailable: (type) => {
          return type && type.id === 'endBrandText'
        },
        options: END_BRAND_LAYOUT,
      }),

      texts: Text({
        dependencies: [
          'decoration.endSection.upperText',
          'decoration.endSection.middleText',
          'decoration.endSection.lowerText',
        ],
        componentName: 'EndSectionTexts',
        isAvailable: (upperText, middleText, lowerText) =>
          some([upperText, middleText, lowerText], (x) => x !== undefined),
      }),
    },

    knob: {
      type: Select({
        ...boilerplate(getPropDef(`decoration_knob_type`)),
        dependencies: ['filter.category'],
        isAvailable: (category) =>
          !!category &&
          isPropDefAvailable(
            getPropDef(`decoration_knob_type`).availability[category.id],
          ),
        options: (category) => {
          const avail =
            getPropDef(`decoration_knob_type`).availability[category.id]
          if (!avail) return []
          if (avail === 'TRUE') return KNOB_DECO_TYPE
          return map(avail.split(','), (x) => KNOB_DECO_TYPE_DICT[x.trim()])
        },
      }),

      decal: Select({
        ...boilerplate(getPropDef(`decoration_knob_decal`)),
        dependencies: ['filter.category', 'decoration.knob.type'],
        isAvailable: (category, type) =>
          !!category &&
          !!type &&
          type.id === 'decal' &&
          isPropDefAvailable(
            getPropDef(`decoration_knob_text`).availability[category.id],
          ),
        options: KNOB_DECAL,
      }),

      text: Text({
        ...boilerplate(getPropDef(`decoration_knob_text`)),
        dependencies: ['filter.category', 'decoration.knob.type'],
        isAvailable: (category, type) =>
          !!category &&
          !!type &&
          type.id === 'text' &&
          isPropDefAvailable(
            getPropDef(`decoration_knob_text`).availability[category.id],
          ),
        maxLength: 10,
      }),

      color: Select({
        ...boilerplate(getPropDef(`decoration_knob_text`)),
        dependencies: ['filter.category', 'decoration.knob.type'],
        isRequired: (category) =>
          !!category &&
          isPropDefAvailable(
            PROP_DEF_DICT.decoration_knob_text.availability[category.id],
          ),
        isAvailable: (category, type) => {
          return (
            !!category &&
            !!type &&
            type.id === 'text' &&
            isPropDefAvailable(
              getPropDef(`decoration_knob_color`).availability[category.id],
            )
          )
        },
        options: KNOB_TEXT_COLOR,
      }),
    },
  },

  options: {
    gripTape: Select({
      ...boilerplate(PROP_DEF_DICT.options_gripTape),
      dependencies: ['filter.category'],
      isAvailable: (category) =>
        !!category &&
        isPropDefAvailable(
          PROP_DEF_DICT.options_gripTape.availability[category.id],
        ),
      options: GRIP_TAPE,
    }),

    cuppedEnd: Select({
      ...boilerplate(PROP_DEF_DICT.options_cuppedEnd),
      dependencies: ['filter.category'],
      isAvailable: (category) =>
        !!category &&
        isPropDefAvailable(
          PROP_DEF_DICT.options_cuppedEnd.availability[category.id],
        ),
      options: CUPPED_END,
    }),

    knobStyle: Select({
      ...boilerplate(PROP_DEF_DICT.options_knobStyle),
      dependencies: ['filter.category'],
      isAvailable: (category) =>
        !!category &&
        isPropDefAvailable(
          PROP_DEF_DICT.options_knobStyle.availability[category.id],
        ),
      options: KNOB_STYLE,
    }),

    ...['displayTube'].reduce((r, x) => {
      const propDef = getPropDef(`options_${x}`)
      return {
        ...r,
        [x]: Select({
          ...boilerplate(propDef),
          dependencies: ['filter.category'],
          isAvailable: (category) =>
            !!category && isPropDefAvailable(propDef.availability[category.id]),
          options: YES_OR_NO,
        }),
      }
    }, {}),
  },

  summary: {
    purchase: Text({
      isPrivate: true,
      value: () => () => true,
      label: 'End',
    }),
  },
})

export default controlTree

export const withCT = withProps({ controlTree })
