// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ViewDefRow } from '../typings';

const data = [
  {
    "id": "custom_overview",
    "groupId": "custom",
    "viewName": "overview",
    "cameraDef": {
      "detachControl": false,
      "alpha": 0.5,
      "beta": 1.6,
      "radius": 1.7,
      "desktopRadius": 2.5,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.5,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.05
      }
    }
  },
  {
    "id": "custom_preview",
    "groupId": "custom",
    "viewName": "preview",
    "cameraDef": {
      "detachControl": true,
      "alpha": 0.5,
      "beta": 1.6,
      "radius": 1.7,
      "desktopRadius": 1.7,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.1,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.05
      }
    }
  },
  {
    "id": "custom_batMiddle",
    "groupId": "custom",
    "viewName": "batMiddle",
    "cameraDef": {
      "detachControl": true,
      "alpha": -0.6,
      "beta": 1.570796327,
      "radius": 0.27,
      "desktopRadius": 0.885,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": -0.05,
        "y": 0,
        "z": -0.07
      }
    }
  },
  {
    "id": "custom_barrelMiddle",
    "groupId": "custom",
    "viewName": "barrelMiddle",
    "cameraDef": {
      "detachControl": true,
      "alpha": -0.6,
      "beta": 1.570796327,
      "radius": 0.27,
      "desktopRadius": 0.885,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.192
      }
    }
  },
  {
    "id": "custom_barrelEnd",
    "groupId": "custom",
    "viewName": "barrelEnd",
    "cameraDef": {
      "detachControl": true,
      "alpha": 0,
      "beta": 1.570796327,
      "radius": 0.25,
      "desktopRadius": 0.485,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.32
      }
    }
  },
  {
    "id": "custom_handle",
    "groupId": "custom",
    "viewName": "handle",
    "cameraDef": {
      "detachControl": true,
      "alpha": -0.6,
      "beta": 1.570796327,
      "radius": 0.9,
      "desktopRadius": 1.2,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": 0.16
      }
    }
  },
  {
    "id": "custom_barrel",
    "groupId": "custom",
    "viewName": "barrel",
    "cameraDef": {
      "detachControl": true,
      "alpha": -0.6,
      "beta": 1.570796327,
      "radius": 0.67,
      "desktopRadius": 1.085,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": -0.15,
        "y": 0,
        "z": -0.17
      }
    }
  },
  {
    "id": "custom_end",
    "groupId": "custom",
    "viewName": "end",
    "cameraDef": {
      "detachControl": true,
      "alpha": 1.2,
      "beta": 1.570796327,
      "radius": 0.27,
      "desktopRadius": 0.885,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0.04,
        "y": 0,
        "z": -0.265
      }
    }
  },
  {
    "id": "custom_knob",
    "groupId": "custom",
    "viewName": "knob",
    "cameraDef": {
      "detachControl": true,
      "alpha": -1.2,
      "beta": 1.570796327,
      "radius": 0.55,
      "desktopRadius": 1.025,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": 0.32
      }
    }
  },
  {
    "id": "mini_overview",
    "groupId": "mini",
    "viewName": "overview",
    "cameraDef": {
      "detachControl": false,
      "alpha": 0.5,
      "beta": 1.6,
      "radius": 0.85,
      "desktopRadius": 0.75,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.1,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.05
      }
    }
  },
  {
    "id": "mini_preview",
    "groupId": "mini",
    "viewName": "preview",
    "cameraDef": {
      "detachControl": true,
      "alpha": 0.5,
      "beta": 1.6,
      "radius": 0.85,
      "desktopRadius": 0.625,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.1,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.05
      }
    }
  },
  {
    "id": "mini_batMiddle",
    "groupId": "mini",
    "viewName": "batMiddle",
    "cameraDef": {
      "detachControl": true,
      "alpha": -0.6,
      "beta": 1.570796327,
      "radius": 0.135,
      "desktopRadius": 0.8175,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.07
      }
    }
  },
  {
    "id": "mini_barrelMiddle",
    "groupId": "mini",
    "viewName": "barrelMiddle",
    "cameraDef": {
      "detachControl": true,
      "alpha": -0.6,
      "beta": 1.570796327,
      "radius": 0.135,
      "desktopRadius": 0.8175,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.192
      }
    }
  },
  {
    "id": "mini_barrelEnd",
    "groupId": "mini",
    "viewName": "barrelEnd",
    "cameraDef": {
      "detachControl": true,
      "alpha": -0.6,
      "beta": 1.570796327,
      "radius": 0.125,
      "desktopRadius": 0.8125,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.265
      }
    }
  },
  {
    "id": "mini_handle",
    "groupId": "mini",
    "viewName": "handle",
    "cameraDef": {
      "detachControl": true,
      "alpha": -0.6,
      "beta": 1.570796327,
      "radius": 0.45,
      "desktopRadius": 0.975,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": 0.16
      }
    }
  },
  {
    "id": "mini_barrel",
    "groupId": "mini",
    "viewName": "barrel",
    "cameraDef": {
      "detachControl": true,
      "alpha": -0.6,
      "beta": 1.570796327,
      "radius": 0.335,
      "desktopRadius": 0.9175,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.17
      }
    }
  },
  {
    "id": "mini_end",
    "groupId": "mini",
    "viewName": "end",
    "cameraDef": {
      "detachControl": true,
      "alpha": 1.2,
      "beta": 1.570796327,
      "radius": 0.135,
      "desktopRadius": 0.8175,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.265
      }
    }
  },
  {
    "id": "mini_knob",
    "groupId": "mini",
    "viewName": "knob",
    "cameraDef": {
      "detachControl": true,
      "alpha": -1.2,
      "beta": 1.570796327,
      "radius": 0.275,
      "desktopRadius": 0.8875,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": 0.32
      }
    }
  },
  {
    "id": "fungo_overview",
    "groupId": "fungo",
    "viewName": "overview",
    "cameraDef": {
      "detachControl": false,
      "alpha": 0.5,
      "beta": 1.6,
      "radius": 1.25,
      "desktopRadius": 1.5,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.1,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.05
      }
    }
  },
  {
    "id": "fungo_preview",
    "groupId": "fungo",
    "viewName": "preview",
    "cameraDef": {
      "detachControl": true,
      "alpha": 0.5,
      "beta": 1.6,
      "radius": 1.25,
      "desktopRadius": 1.25,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.1,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.05
      }
    }
  },
  {
    "id": "fungo_batMiddle",
    "groupId": "fungo",
    "viewName": "batMiddle",
    "cameraDef": {
      "detachControl": true,
      "alpha": -0.6,
      "beta": 1.570796327,
      "radius": 0.2,
      "desktopRadius": 0.85,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.07
      }
    }
  },
  {
    "id": "fungo_barrelMiddle",
    "groupId": "fungo",
    "viewName": "barrelMiddle",
    "cameraDef": {
      "detachControl": true,
      "alpha": -0.6,
      "beta": 1.570796327,
      "radius": 0.2,
      "desktopRadius": 0.85,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.192
      }
    }
  },
  {
    "id": "fungo_barrelEnd",
    "groupId": "fungo",
    "viewName": "barrelEnd",
    "cameraDef": {
      "detachControl": true,
      "alpha": -0.6,
      "beta": 1.570796327,
      "radius": 0.2,
      "desktopRadius": 0.85,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.265
      }
    }
  },
  {
    "id": "fungo_handle",
    "groupId": "fungo",
    "viewName": "handle",
    "cameraDef": {
      "detachControl": true,
      "alpha": -0.6,
      "beta": 1.570796327,
      "radius": 0.67,
      "desktopRadius": 1.085,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": 0.16
      }
    }
  },
  {
    "id": "fungo_barrel",
    "groupId": "fungo",
    "viewName": "barrel",
    "cameraDef": {
      "detachControl": true,
      "alpha": -0.6,
      "beta": 1.570796327,
      "radius": 0.5,
      "desktopRadius": 1,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.17
      }
    }
  },
  {
    "id": "fungo_end",
    "groupId": "fungo",
    "viewName": "end",
    "cameraDef": {
      "detachControl": true,
      "alpha": 1.2,
      "beta": 1.570796327,
      "radius": 0.2,
      "desktopRadius": 0.85,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": -0.265
      }
    }
  },
  {
    "id": "fungo_knob",
    "groupId": "fungo",
    "viewName": "knob",
    "cameraDef": {
      "detachControl": true,
      "alpha": -1.2,
      "beta": 1.570796327,
      "radius": 0.4,
      "desktopRadius": 0.95,
      "fovDegrees": 27,
      "fov": 0.471238898,
      "meshRotation": {
        "x": -0.15,
        "y": 0,
        "z": 0
      },
      "meshPosition": {
        "x": 0,
        "y": 0,
        "z": 0.32
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ViewDefRow[]>> = data;

export type ViewDef = typeof typed[number];

export const VIEW_DEF_INDEX_KEY = "id";
export type ViewDefIndexKey = "id";
export type ViewDefId = ViewDef["id"];

let i = 0;
export const VIEW_DEF_DICT = {
  "custom_overview": typed[i++],
  "custom_preview": typed[i++],
  "custom_batMiddle": typed[i++],
  "custom_barrelMiddle": typed[i++],
  "custom_barrelEnd": typed[i++],
  "custom_handle": typed[i++],
  "custom_barrel": typed[i++],
  "custom_end": typed[i++],
  "custom_knob": typed[i++],
  "mini_overview": typed[i++],
  "mini_preview": typed[i++],
  "mini_batMiddle": typed[i++],
  "mini_barrelMiddle": typed[i++],
  "mini_barrelEnd": typed[i++],
  "mini_handle": typed[i++],
  "mini_barrel": typed[i++],
  "mini_end": typed[i++],
  "mini_knob": typed[i++],
  "fungo_overview": typed[i++],
  "fungo_preview": typed[i++],
  "fungo_batMiddle": typed[i++],
  "fungo_barrelMiddle": typed[i++],
  "fungo_barrelEnd": typed[i++],
  "fungo_handle": typed[i++],
  "fungo_barrel": typed[i++],
  "fungo_end": typed[i++],
  "fungo_knob": typed[i++]
} as const;

export { typed as VIEW_DEFS };
