import {
  Color3,
  PBRMaterial,
  PBRMetallicRoughnessMaterial,
} from '@babylonjs/core'

const createBatMaterial = (scene) => {
  const batMaterial = new PBRMetallicRoughnessMaterial('batMaterial', scene)
  batMaterial.baseColor = new Color3(0.9, 0.9, 0.9)
  batMaterial.metallic = 1
  batMaterial.roughness = 1
  batMaterial.environmentIntensity = 0.75

  return batMaterial
}

const createGripTapeMaterial = (scene) => {
  const batMaterial = new PBRMetallicRoughnessMaterial(
    'gripTapeMaterial',
    scene,
  )
  batMaterial.baseColor = new Color3(0.9, 0.9, 0.9)
  batMaterial.metallic = 1
  batMaterial.roughness = 1
  batMaterial.environmentIntensity = 0.75

  return batMaterial
}

const createEndBrandMaterial = (scene) => {
  const material = new PBRMaterial('endBrandMaterial', scene)
  material.environmentIntensity = 0.75
  material.albedoColor = new Color3(0.6, 0.6, 0.6)

  material.useParallax = true
  material.useParallaxOcclusion = true
  material.parallaxScaleBias = 0.01

  material.useAlphaFromAlbedoTexture = true
  material.useRadianceOverAlpha = false

  material.roughness = 0.6

  material.clearCoat.isEnabled = true
  material.clearCoat.intensity = 1
  material.clearCoat.roughness = 0.2

  return material
}

const createDecalBubbleMaterial = (scene) => {
  const material = new PBRMaterial('decalbubbleMaterial', scene)
  material.albedoColor = new Color3(0.7, 0.7, 0.7)
  material.roughness = 0.1
  material.metallic = 1
  material.alpha = 0.5
  material.environmentIntensity = 0.5
  material.enableSpecularAntiAliasing = true

  material.subSurface.isRefractionEnabled = true
  material.subSurface.refractionIntensity = 1
  material.subSurface.indexOfRefraction = 1.5

  material.subSurface.isTranslucencyEnabled = true
  material.subSurface.translucencyIntensity = 1

  material.clearCoat.isEnabled = true
  material.clearCoat.intensity = 1
  material.clearCoat.roughness = 0

  return material
}

const createDecalBaseMaterial = (scene) => {
  const material = new PBRMaterial('decalBaseMaterial', scene)
  material.albedoColor = new Color3(0.9, 0.9, 0.9)
  material.roughness = 0.8
  material.metallic = 0

  return material
}

export {
  createBatMaterial,
  createEndBrandMaterial,
  createGripTapeMaterial,
  createDecalBaseMaterial,
  createDecalBubbleMaterial,
}
