import { compact, each, transform, uniq } from '@technically/lodash'

import getAsset from '~p/getAsset'

import {
  expandQuery,
  updateText,
  createSvgDrawing,
} from '~p/client/renderSvg/svgUtils'
import { toNumberOrDie } from '@orangelv/utils'

import { FONT_DICT, SURFACE_MATERIAL_DICT } from '../common/sheets'

const getTextDef = (textValue, fontValue) => {
  if (textValue == null || fontValue == null) {
    return {
      text: undefined,
      font: null,
    }
  }
  return {
    text: fontValue.props.forceUpperCase ? textValue.toUpperCase() : textValue,
    font: fontValue.props.fontFamily,
  }
}

const getFontUrls = (fontList) =>
  transform(
    uniq(compact(fontList)),
    (result, font) => {
      result[font.props.assetId] = getAsset(`fonts/${font.props.assetId}.css`)
    },
    {},
  )

const getSurfaceMetallicRoughness = (
  materialId,
  fallbackMaterial = SURFACE_MATERIAL_DICT.unfinished,
) => {
  if (!materialId) return fallbackMaterial.props.metallicRoughness

  const mat = SURFACE_MATERIAL_DICT[materialId]
  if (!mat) return fallbackMaterial.props.metallicRoughness

  return mat.props.metallicRoughness
}

const getAssetDef = (data) => {
  const endSection = data.decoration.endSection || {}

  return {
    svg:
      endSection.endBrandLayout ?
        getAsset(`endBrandLayouts/layout${endSection.endBrandLayout.id}.svg`)
      : undefined,
    fonts: getFontUrls([
      ...(data.decoration.endSection.endBrandLayout ?
        [FONT_DICT.underdog, FONT_DICT.gobold]
      : []),
    ]),
  }
}

const getDiffuseFillData = (data) => ({
  endSection: data.decoration.endSection?.color?.props.hex ?? null,
})

const getNormalFillData = () => ({ endSection: '#000000' })

const getMetallicRoughnessFillData = (data) => {
  const endSection = data.decoration.endSection || {}

  return {
    endSection:
      endSection.color?.props.isMetallic ?
        getSurfaceMetallicRoughness('foilSticker')
      : null,
  }
}

const getTextDimensions = (textElement, svgDrawing) => {
  // Using a temporary svg for text measurement
  // Reason: text dimensions are not correctly detected by svg.js
  // in the initial drawing when using custom fonts
  // https://github.com/svgdotjs/svg.js/issues/1267
  const tempContainer = document.createElement('div')
  tempContainer.style.position = 'absolute'
  tempContainer.style.visibility = 'hidden'
  document.body.appendChild(tempContainer)

  const tempSvg = SVG(tempContainer).size(500, 500)
  const defs = svgDrawing.defs()
  if (defs) {
    tempSvg.defs().svg(defs.svg())
  }

  const tempText = tempSvg
    .text((add) => {
      add.tspan(textElement.text())
    })
    .font({
      family: textElement.attr('font-family'),
      size: textElement.attr('font-size'),
      weight: textElement.attr('font-weight'),
    })

  const isRotated = textElement.transform().rotation !== 0

  const width = isRotated ? tempText.bbox().height : tempText.bbox().width
  const height = isRotated ? tempText.bbox().width : tempText.bbox().height

  // Clean up
  tempContainer.remove()

  return { width, height }
}

const justifySiblings = (elements, boundingElement, drawing) => {
  const ELEMENT_SPACING = 15

  // Calculate total width and element widths in one pass
  const { totalWidth, elementWidths } = elements.reduce(
    (acc, el) => {
      const { width } = getTextDimensions(el, drawing)
      return {
        totalWidth: acc.totalWidth + width + ELEMENT_SPACING,
        elementWidths: [...acc.elementWidths, width],
      }
    },
    { totalWidth: -ELEMENT_SPACING, elementWidths: [] },
  )

  const startX = boundingElement.cx() - totalWidth / 2

  for (const [index, element] of elements.entries()) {
    const width = elementWidths[index]

    // Calculate the cumulative width of all previous elements plus spacing
    const previousWidthsAndSpacing = elementWidths
      .slice(0, index)
      .reduce((sum, w) => sum + w + ELEMENT_SPACING, 0)

    // Calculate the x position for the current element
    const xPosition = startX + previousWidthsAndSpacing + width / 2

    element
      .attr({
        'text-anchor': 'middle',
        'dominant-baseline': 'middle',
      })
      .transform({
        x: xPosition,
        y: boundingElement.cy(),
      })
  }
}

const scaleAndCenterText = (textElement, boundingElement, svgDrawing) => {
  const boundaryBox = boundingElement.bbox()

  const { width: textWidth, height: textHeight } = getTextDimensions(
    textElement,
    svgDrawing,
  )

  const scaleX = boundaryBox.width / textWidth
  const scaleY = boundaryBox.height / textHeight
  const scale = Math.min(scaleX, scaleY, 1)
  textElement
    .transform({
      scale: scale,
    })
    .transform({
      x: boundaryBox.cx,
      y: boundaryBox.cy,
    })
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle')
}

const convertInchNotation = (input) => {
  if (typeof input !== 'string' || !input.endsWith('"')) return input
  try {
    const numberPart = toNumberOrDie(input.slice(0, -1))

    return `${numberPart} in.`
  } catch {
    return input
  }
}

const getTextData = (data) => {
  const endSection = data.decoration.endSection || {}

  return {
    endBrand: {
      name: getTextDef(
        endSection?.endBrandText?.toUpperCase(),
        FONT_DICT.block,
      ),
      model: getTextDef(data.product.model.name.toUpperCase(), FONT_DICT.block),
      length: getTextDef(
        convertInchNotation(data.product.length.name),
        FONT_DICT.block,
      ),
      woodSpecies: getTextDef(
        data.product.woodSpecies.name.toUpperCase(),
        FONT_DICT.block,
      ),
    },
  }
}

const getData = (data, type) => {
  const fillFunctions = {
    diffuse: getDiffuseFillData,
    normal: getNormalFillData,
    metallic: getMetallicRoughnessFillData,
  }

  const fillFunction = fillFunctions[type] || fillFunctions.diffuse

  return {
    fill: fillFunction(data),
    text: getTextData(data),
    type,
    endBrandLayout: data.decoration.endSection.endBrandLayout,
    baseNormal: getAsset('bats/rough_normal.jpg'),
  }
}

const getBaseSvg = (svg) => createSvgDrawing(svg)

const getDynamicSvg = (data, size, baseSvg, images, svgs, fonts) => {
  const drawing = baseSvg
  drawing.size(size.width, size.height)
  drawing.defs().clear()

  // TEXT
  const styleNode = drawing
    .defs()
    .node.appendChild(document.createElement('style'))
  each(fonts, (font) => styleNode.appendChild(document.createTextNode(font)))

  drawing
    .select(expandQuery(['$static path', '$static rect'].toString()))
    .each((i, children) => {
      const element = children[i]
      element.attr(
        'fill',
        data.type === 'normal' ? '#000000' : data.fill.endSection,
      )
    })

  const endBrandTexts = [
    {
      selector: '$name',
      textKey: 'endBrand.name',
      font: 'Gobold',
    },
    {
      selector: '$model',
      textKey: 'endBrand.model',
      font: 'UnderdogAllStar',
    },
    {
      selector: '$wood-species',
      textKey: 'endBrand.woodSpecies',
      font: 'UnderdogAllStar',
    },
    {
      selector: '$length',
      textKey: 'endBrand.length',
      font: 'UnderdogAllStar',
    },
  ]

  drawing.select(expandQuery('text')).each((i, children) => {
    const text = children[i]
    text.attr('text-anchor', 'middle')
    text.attr('dominant-baseline', 'middle')
  })

  const shouldJustifyBottomTexts = drawing
    .select(expandQuery('$bottom-details'))
    .first()

  for (const element of endBrandTexts) {
    const { selector, textKey, font } = element
    const [mainKey, subKey] = textKey.split('.')
    const textData = data.text[mainKey][subKey]

    updateText(
      drawing,
      expandQuery(selector + ' text'),
      textData.text,
      font,
      data.type === 'normal' ? '#000000' : data.fill.endSection,
    )

    const textElement = drawing.select(expandQuery(selector + ' text')).first()
    const boundingElement = drawing
      .select(expandQuery(selector + ' $box'))
      .first()

    if (!shouldJustifyBottomTexts || subKey === 'name') {
      scaleAndCenterText(textElement, boundingElement, drawing)
    }

    if (shouldJustifyBottomTexts) {
      const boundingElement = drawing
        .select(expandQuery('$bottom-details $box'))
        .first()
      const textElements = drawing.select(expandQuery('$bottom-details text'))

      textElements.each((i, children) => {
        const element = children[i]
        element.attr(
          'fill',
          data.type === 'normal' ? '#000000' : data.fill.endSection,
        )
      })

      justifySiblings(textElements.members.reverse(), boundingElement, drawing)
    }
  }

  return drawing
}

const instructions = {
  getBaseSvg,
  getDynamicSvg,
}

export const getNormalDef = (values, size) => ({
  name: 'endBrand-normal',
  assets: getAssetDef(values, size, true),
  data: getData(values, 'normal'),
  size,
  instructions,
})

export const getDiffuseDef = (values, size) => ({
  name: 'endBrand-diffuse',
  assets: getAssetDef(values, size, true),
  data: getData(values, 'diffuse'),
  size,
  instructions,
})

export const getRoughnessDef = (values, size) => ({
  name: 'endBrand-roughness',
  assets: getAssetDef(values, size, false),
  data: getData(values, 'metallic'),
  size,
  instructions,
})
