// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { NavTreeItemRow } from '../typings';

const data = [
  {
    "childId": "filter",
    "name": "Filters",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "filter"
  },
  {
    "parentId": "filter",
    "childId": "productSelector",
    "name": "Bat Finder",
    "isWizardStep": false,
    "shouldShowChildren": true,
    "id": "filter.productSelector"
  },
  {
    "parentId": "filter.productSelector",
    "childId": "group",
    "name": "Group",
    "propId": "filter.group",
    "isWizardStep": true,
    "wizardTitle": "How will you <strong>use</strong> this bat?",
    "shouldShowChildren": false,
    "id": "filter.productSelector.group"
  },
  {
    "parentId": "filter.productSelector",
    "childId": "category",
    "name": "Category",
    "propId": "filter.category",
    "isWizardStep": true,
    "wizardTitle": "What <strong>type</strong> would you like?",
    "shouldShowChildren": false,
    "id": "filter.productSelector.category"
  },
  {
    "parentId": "filter.productSelector",
    "childId": "series",
    "name": "Series",
    "propId": "filter.series",
    "isWizardStep": true,
    "wizardTitle": "Which <strong>series</strong> will you swing?",
    "shouldShowChildren": false,
    "id": "filter.productSelector.series"
  },
  {
    "parentId": "filter.productSelector",
    "childId": "model",
    "name": "Model",
    "propId": "product.model",
    "isWizardStep": true,
    "wizardTitle": "Which <strong>model</strong> would you like to customize?",
    "shouldShowChildren": false,
    "id": "filter.productSelector.model"
  },
  {
    "childId": "product",
    "name": "Product",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "product"
  },
  {
    "parentId": "product",
    "childId": "woodSpecies",
    "name": "Wood Species",
    "propId": "product.woodSpecies",
    "isWizardStep": false,
    "wizardTitle": "What <strong>wood</strong> do you prefer?",
    "shouldShowChildren": false,
    "id": "product.woodSpecies"
  },
  {
    "parentId": "product",
    "childId": "length",
    "name": "Length",
    "propId": "product.length",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "product.length"
  },
  {
    "parentId": "product",
    "childId": "weight",
    "name": "Weight",
    "propId": "product.weight",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "product.weight"
  },
  {
    "childId": "options",
    "name": "Options",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "options"
  },
  {
    "parentId": "options",
    "childId": "gripTape",
    "name": "Grip Tape",
    "propId": "options.gripTape",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "options.gripTape"
  },
  {
    "parentId": "options",
    "childId": "cuppedEnd",
    "name": "Cupped End",
    "propId": "options.cuppedEnd",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "options.cuppedEnd"
  },
  {
    "parentId": "options",
    "childId": "knobStyle",
    "name": "Knob Style",
    "propId": "options.knobStyle",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "options.knobStyle"
  },
  {
    "parentId": "options",
    "childId": "displayTube",
    "name": "Display Tube",
    "propId": "options.displayTube",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "options.displayTube"
  },
  {
    "childId": "colors",
    "name": "Colors",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "colors"
  },
  {
    "parentId": "colors",
    "childId": "colorCombo",
    "name": "Color Combo",
    "propId": "colors.colorCombo",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "colors.colorCombo"
  },
  {
    "parentId": "colors",
    "childId": "batColor",
    "name": "Bat",
    "propId": "colors.batColor",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "colors.batColor"
  },
  {
    "parentId": "colors",
    "childId": "barrel",
    "name": "Barrel",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "colors.barrel"
  },
  {
    "parentId": "colors.barrel",
    "childId": "type",
    "name": "Color",
    "propId": "colors.barrel.type",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "colors.barrel.type"
  },
  {
    "parentId": "colors.barrel",
    "childId": "finish",
    "name": "Finish",
    "propId": "colors.barrel.finish",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "colors.barrel.finish"
  },
  {
    "parentId": "colors",
    "childId": "handle",
    "name": "Handle",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "colors.handle"
  },
  {
    "parentId": "colors.handle",
    "childId": "type",
    "name": "Color",
    "propId": "colors.handle.type",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "colors.handle.type"
  },
  {
    "parentId": "colors.handle",
    "childId": "finish",
    "name": "Finish",
    "propId": "colors.handle.finish",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "colors.handle.finish"
  },
  {
    "parentId": "colors",
    "childId": "stripe",
    "name": "Stripe",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "colors.stripe"
  },
  {
    "parentId": "colors.stripe",
    "childId": "size",
    "name": "Size",
    "propId": "colors.stripe.size",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "colors.stripe.size"
  },
  {
    "parentId": "colors.stripe",
    "childId": "color",
    "name": "Color",
    "propId": "colors.stripe.color",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "colors.stripe.color"
  },
  {
    "parentId": "colors",
    "childId": "ovalLogo",
    "name": "Rawlings Logo",
    "propId": "colors.ovalLogo",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "colors.ovalLogo"
  },
  {
    "childId": "decoration",
    "name": "Decoration",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration"
  },
  {
    "parentId": "decoration",
    "childId": "middleSection",
    "name": "Middle Section",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.middleSection"
  },
  {
    "parentId": "decoration.middleSection",
    "childId": "type",
    "name": "Type",
    "propId": "decoration.middleSection.type",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.middleSection.type"
  },
  {
    "parentId": "decoration.middleSection",
    "childId": "customLogo",
    "name": "Custom Logo",
    "propId": "decoration.middleSection.customLogo",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.middleSection.customLogo"
  },
  {
    "parentId": "decoration.middleSection",
    "childId": "stockLogo",
    "name": "Stock Logo",
    "propId": "decoration.middleSection.stockLogo",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.middleSection.stockLogo"
  },
  {
    "parentId": "decoration.middleSection",
    "childId": "color",
    "name": "Color",
    "propId": "decoration.middleSection.color",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.middleSection.color"
  },
  {
    "parentId": "decoration",
    "childId": "endSection",
    "name": "End Section",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.endSection"
  },
  {
    "parentId": "decoration.endSection",
    "childId": "type",
    "name": "Type",
    "propId": "decoration.endSection.type",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.endSection.type"
  },
  {
    "parentId": "decoration.endSection",
    "childId": "customLogo",
    "name": "Custom Logo",
    "propId": "decoration.endSection.customLogo",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.endSection.customLogo"
  },
  {
    "parentId": "decoration.endSection",
    "childId": "stockLogo",
    "name": "Stock Logo",
    "propId": "decoration.endSection.stockLogo",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.endSection.stockLogo"
  },
  {
    "parentId": "decoration.endSection",
    "childId": "texts",
    "name": "Texts",
    "propId": "decoration.endSection.texts",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.endSection.texts"
  },
  {
    "parentId": "decoration.endSection",
    "childId": "endBrandLayout",
    "name": "Layout",
    "propId": "decoration.endSection.endBrandLayout",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.endSection.endBrandLayout"
  },
  {
    "parentId": "decoration.endSection",
    "childId": "endBrandText",
    "name": "Text",
    "propId": "decoration.endSection.endBrandText",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.endSection.endBrandText"
  },
  {
    "parentId": "decoration.endSection",
    "childId": "color",
    "name": "Color",
    "propId": "decoration.endSection.color",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.endSection.color"
  },
  {
    "parentId": "decoration",
    "childId": "knob",
    "name": "Knob",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.knob"
  },
  {
    "parentId": "decoration.knob",
    "childId": "type",
    "name": "Type",
    "propId": "decoration.knob.type",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.knob.type"
  },
  {
    "parentId": "decoration.knob",
    "childId": "decal",
    "name": "Decal",
    "propId": "decoration.knob.decal",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.knob.decal"
  },
  {
    "parentId": "decoration.knob",
    "childId": "text",
    "name": "Text",
    "propId": "decoration.knob.text",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.knob.text"
  },
  {
    "parentId": "decoration.knob",
    "childId": "color",
    "name": "Color",
    "propId": "decoration.knob.color",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "id": "decoration.knob.color"
  },
  {
    "childId": "summary",
    "name": "Summary",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "layoutMode": "mobile",
    "id": "summary"
  },
  {
    "parentId": "summary",
    "childId": "purchase",
    "name": "Purchase",
    "isWizardStep": false,
    "shouldShowChildren": false,
    "layoutMode": "mobile",
    "id": "summary.purchase"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<NavTreeItemRow[]>> = data;

export type NavTreeItem = typeof typed[number];

export const NAV_TREE_ITEM_INDEX_KEY = "id";
export type NavTreeItemIndexKey = "id";
export type NavTreeItemId = NavTreeItem["id"];

let i = 0;
export const NAV_TREE_ITEM_DICT = {
  "filter": typed[i++],
  "filter.productSelector": typed[i++],
  "filter.productSelector.group": typed[i++],
  "filter.productSelector.category": typed[i++],
  "filter.productSelector.series": typed[i++],
  "filter.productSelector.model": typed[i++],
  "product": typed[i++],
  "product.woodSpecies": typed[i++],
  "product.length": typed[i++],
  "product.weight": typed[i++],
  "options": typed[i++],
  "options.gripTape": typed[i++],
  "options.cuppedEnd": typed[i++],
  "options.knobStyle": typed[i++],
  "options.displayTube": typed[i++],
  "colors": typed[i++],
  "colors.colorCombo": typed[i++],
  "colors.batColor": typed[i++],
  "colors.barrel": typed[i++],
  "colors.barrel.type": typed[i++],
  "colors.barrel.finish": typed[i++],
  "colors.handle": typed[i++],
  "colors.handle.type": typed[i++],
  "colors.handle.finish": typed[i++],
  "colors.stripe": typed[i++],
  "colors.stripe.size": typed[i++],
  "colors.stripe.color": typed[i++],
  "colors.ovalLogo": typed[i++],
  "decoration": typed[i++],
  "decoration.middleSection": typed[i++],
  "decoration.middleSection.type": typed[i++],
  "decoration.middleSection.customLogo": typed[i++],
  "decoration.middleSection.stockLogo": typed[i++],
  "decoration.middleSection.color": typed[i++],
  "decoration.endSection": typed[i++],
  "decoration.endSection.type": typed[i++],
  "decoration.endSection.customLogo": typed[i++],
  "decoration.endSection.stockLogo": typed[i++],
  "decoration.endSection.texts": typed[i++],
  "decoration.endSection.endBrandLayout": typed[i++],
  "decoration.endSection.endBrandText": typed[i++],
  "decoration.endSection.color": typed[i++],
  "decoration.knob": typed[i++],
  "decoration.knob.type": typed[i++],
  "decoration.knob.decal": typed[i++],
  "decoration.knob.text": typed[i++],
  "decoration.knob.color": typed[i++],
  "summary": typed[i++],
  "summary.purchase": typed[i++]
} as const;

export { typed as NAV_TREE };
