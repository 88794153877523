import Bluebird from 'bluebird'
import { DynamicTexture } from '@babylonjs/core'

const readAsDataURL = (blob, url) => {
  const encoder = new FileReader()

  return new Bluebird((resolve, reject, onCancel) => {
    onCancel(() => {
      encoder.abort()
    })

    encoder.onload = () => {
      resolve(encoder.result)
    }
    encoder.onerror = () => {
      reject(new Error(`Can not read resource as dataURL: ${url}`))
    }
    encoder.onabort = () => {
      reject(new Error(`Aborted readAsDataURL: ${url}`))
    }

    encoder.readAsDataURL(blob)
  })
}

export const getImageCanvasTexture = (url, textureId) => {
  const image = new Image()
  image.crossOrigin = 'Anonymous'

  return new Bluebird((resolve, reject, onCancel) => {
    onCancel(() => {
      image.src = ''
    })

    image.onload = () => {
      const texture = new DynamicTexture(
        textureId,
        {
          width: image.width,
          height: image.height,
        },
        null,
        true,
      )
      const ctx = texture.getContext()
      ctx.drawImage(image, 0, 0)
      texture.update()
      resolve(texture)
    }
    image.onerror = (error) => {
      reject(
        new Error(
          `Can not read resource as dataURL: ${url}, error: ${error.message}`,
        ),
      )
    }
    image.onabort = () => {
      reject(new Error(`Aborted readAsDataURL: ${url}`))
    }

    image.src = url
  })
}

export const loadImageToDataUri = (url, timeout = 30000) => {
  if (url == null) {
    return Bluebird.resolve('')
  }
  const request = new XMLHttpRequest()

  return new Bluebird((resolve, reject, onCancel) => {
    onCancel(() => {
      request.abort()
    })

    request.onload = () => {
      readAsDataURL(request.response, url).then(resolve).error(reject)
    }
    request.onerror = () => {
      reject(
        new Error(`Can not fetch resource: ${url}, status: ${request.status}`),
      )
    }

    request.open('GET', url, true)
    request.responseType = 'blob'
    request.timeout = timeout
    request.send()
  })
}

export const loadData = (url, timeout = 30000) => {
  if (url == null) {
    return Bluebird.resolve('')
  }
  const request = new XMLHttpRequest()

  return new Bluebird((resolve, reject, onCancel) => {
    onCancel(() => {
      request.abort()
    })

    request.onload = () => {
      resolve(request.response)
    }
    request.onerror = () => {
      reject(
        new Error(`Can not fetch resource: ${url}, status: ${request.status}`),
      )
    }

    request.open('GET', url, true)
    request.timeout = timeout
    request.send()
  })
}
