import { forEach, filter, includes } from '@technically/lodash'
import { PBRMaterial, Vector3 } from '@babylonjs/core'

let prevSceneResult

const updateTexture = (currentTexture, newTexture, options) => {
  if (newTexture && currentTexture !== newTexture) {
    currentTexture?.dispose()
    Object.assign(newTexture, options)
    return newTexture
  }
  return currentTexture
}

const updateModel = (
  scene,
  positionMesh,
  hdr,
  material,
  prevModelDef,
  nextModelDef,
  prevAssets,
  nextAssets,
) => {
  const textures = nextAssets ? nextAssets.textures : {}
  const meshes = nextAssets ? nextAssets.meshes : {}

  const didSceneHdrChange = material.environmentTexture !== hdr
  if (didSceneHdrChange && material) {
    if (hdr) {
      hdr.gammaSpace = false
    }

    material.environmentTexture = hdr
    material.reflectionTexture = hdr
  }

  if (textures) {
    if (material instanceof PBRMaterial) {
      material.albedoTexture = updateTexture(
        material.albedoTexture,
        textures.diffuse,
        { hasAlpha: true },
      )

      material.bumpTexture = updateTexture(
        material.bumpTexture,
        textures.normal,
        { level: 2 },
      )

      material.metallicTexture = updateTexture(
        material.metallicTexture,
        textures.roughness,
      )
    } else {
      material.baseTexture = updateTexture(
        material.baseTexture,
        textures.diffuse,
      )
      material.bumpTexture = updateTexture(
        material.bumpTexture,
        textures.normal,
      )
      material.metallicRoughnessTexture = updateTexture(
        material.metallicRoughnessTexture,
        textures.roughness,
      )
    }
  }

  const prevMesheDefs = prevModelDef ? prevModelDef.meshes : {}
  const nextMesheDefs = nextModelDef ? nextModelDef.meshes : {}
  const didBatMeshChange = prevMesheDefs !== nextMesheDefs
  if (didBatMeshChange) {
    const oldMeshes = filter(
      prevAssets && prevAssets.meshes,
      (mesh) => !includes(meshes, mesh),
    )
    forEach(oldMeshes, (mesh) => {
      scene.removeMesh(mesh)
      mesh.dispose()
    })

    const newMeshes = filter(
      meshes,
      (mesh) => !includes(prevAssets && prevAssets.meshes, mesh),
    )
    forEach(newMeshes, (mesh) => {
      mesh.material = material

      scene.addMesh(mesh)
    })
  }

  const meshDef = nextModelDef
  if (meshDef) {
    forEach(meshes, (mesh, meshId) => {
      mesh.parent = positionMesh
      mesh.alphaIndex = 1

      if (mesh.name.startsWith('end-brand')) {
        mesh.alphaIndex = 2
      }

      if (meshDef.meshVisibility) {
        mesh.isVisible =
          meshId in meshDef.meshVisibility ?
            meshDef.meshVisibility[meshId]
          : true
      }
      mesh.scaling = new Vector3(meshDef.scale, meshDef.scale, meshDef.scale)
    })
  }
}

const updateScene = (
  prevProps,
  nextProps,
  sceneResult,
  sceneContext,
  cameraDef,
) => {
  const { scene, materials, rotationMesh, positionMesh } = sceneContext

  const { meshRotation, meshPosition } = cameraDef
  rotationMesh.rotation = new Vector3(
    meshRotation.x,
    meshRotation.y,
    meshRotation.z,
  )
  positionMesh.position = new Vector3(
    meshPosition.x,
    meshPosition.y,
    meshPosition.z,
  )

  updateModel(
    scene,
    positionMesh,
    sceneResult.scene.hdr,
    materials.bat,
    prevProps ? prevProps.sceneDef.models.bat : null,
    nextProps.sceneDef.models.bat,
    prevSceneResult ? prevSceneResult.models.gripTape : null,
    sceneResult.models.bat,
  )
  updateModel(
    scene,
    positionMesh,
    sceneResult.scene.hdr,
    materials.endBrand,
    prevProps ? prevProps.sceneDef.models.endBrand : null,
    nextProps.sceneDef.models.endBrand,
    prevSceneResult ? prevSceneResult.models.endBrand : null,
    sceneResult.models.endBrand,
  )
  updateModel(
    scene,
    positionMesh,
    sceneResult.scene.hdr,
    materials.gripTape,
    prevProps ? prevProps.sceneDef.models.gripTape : null,
    nextProps.sceneDef.models.gripTape,
    prevSceneResult ? prevSceneResult.models.gripTape : null,
    sceneResult.models.gripTape,
  )
  updateModel(
    scene,
    positionMesh,
    sceneResult.scene.hdr,
    materials.decalBase,
    prevProps ? prevProps.sceneDef.models.decalBase : null,
    nextProps.sceneDef.models.decalBase,
    prevSceneResult ? prevSceneResult.models.decalBase : null,
    sceneResult.models.decalBase,
  )
  updateModel(
    scene,
    positionMesh,
    sceneResult.scene.hdr,
    materials.decalBubble,
    prevProps ? prevProps.sceneDef.models.decalBubble : null,
    nextProps.sceneDef.models.decalBubble,
    prevSceneResult ? prevSceneResult.models.decalBubble : null,
    sceneResult.models.decalBubble,
  )
  prevSceneResult = sceneResult
}

export default updateScene
