// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { PropDefRow } from '../typings';

const data = [
  {
    "name": "Group",
    "id": "filter_group",
    "dataType": "group",
    "tileType": "squareWithText",
    "tileImageProps": "bat-fpo-icon.png",
    "isOptional": false,
    "viewNames": [
      "overview"
    ]
  },
  {
    "name": "Category",
    "id": "filter_category",
    "dataType": "category",
    "tileType": "squareWithText",
    "tileImageProps": "bat-fpo-icon.png",
    "isOptional": true,
    "viewNames": [
      "overview"
    ]
  },
  {
    "name": "Series",
    "id": "filter_series",
    "description": "From knob to end choose the shape, feel and balance to suit your game.",
    "dataType": "series",
    "tileType": "squareWithText",
    "tileImageProps": "bat-fpo-icon.png",
    "isOptional": true,
    "viewNames": [
      "overview"
    ],
    "availability": {
      "customAwardBat": "FALSE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "FALSE",
      "customGameUseBat": "FALSE",
      "customFungoBat": "FALSE"
    }
  },
  {
    "name": "Model",
    "id": "product_model",
    "dataType": "model",
    "tileType": "squareWithText",
    "tileImageProps": "icons/model/${id}.png",
    "isOptional": true,
    "viewNames": [
      "overview"
    ],
    "availability": {
      "customAwardBat": "customAwardBat",
      "personalizedAwardBat": "personalizedAwardBat",
      "customMiniBat": "customMiniBat",
      "customGameUseBat": "TRUE",
      "customFungoBat": "customFungoBat"
    }
  },
  {
    "name": "Wood Species",
    "id": "product_woodSpecies",
    "description": "Choose the wood",
    "dataType": "woodSpecies",
    "tileType": "square",
    "tileImageProps": "icons/woodSpecies/${id}.png",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "availability": {
      "customAwardBat": "ash",
      "personalizedAwardBat": "ash",
      "customMiniBat": "ash",
      "customGameUseBat": "TRUE",
      "customFungoBat": "ash"
    }
  },
  {
    "name": "Length",
    "id": "product_length",
    "description": "Choose the length that best fits you",
    "dataType": "length",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "availability": {
      "customAwardBat": "34",
      "personalizedAwardBat": "34",
      "customMiniBat": "17",
      "customGameUseBat": "TRUE",
      "customFungoBat": "35"
    }
  },
  {
    "name": "Weight",
    "id": "product_weight",
    "description": "Choose the ounce drop",
    "dataType": "weight",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "availability": {
      "customAwardBat": "FALSE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "FALSE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "FALSE"
    }
  },
  {
    "name": "Color Combo",
    "id": "colors_colorCombo",
    "dataType": "colorCombo",
    "tileType": "square",
    "tileImageProps": "colorCombo/${props.imageId}.png",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "availability": {
      "customAwardBat": "FALSE",
      "personalizedAwardBat": "TRUE",
      "customMiniBat": "FALSE",
      "customGameUseBat": "FALSE",
      "customFungoBat": "FALSE"
    }
  },
  {
    "name": "Bat Color",
    "id": "colors_batColor",
    "dataType": "surfaceColor",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "availability": {
      "customAwardBat": "FALSE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "TRUE",
      "customGameUseBat": "FALSE",
      "customFungoBat": "FALSE"
    }
  },
  {
    "name": "Barrel Color",
    "id": "colors_barrel_type",
    "dataType": "surfaceType",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "barrel"
    ],
    "availability": {
      "customAwardBat": "TRUE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "FALSE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "TRUE"
    }
  },
  {
    "name": "Barrel Finish",
    "id": "colors_barrel_finish",
    "dataType": "surfaceFinish",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "barrel"
    ]
  },
  {
    "name": "Handle Color",
    "id": "colors_handle_type",
    "dataType": "surfaceType",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "handle"
    ],
    "availability": {
      "customAwardBat": "TRUE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "FALSE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "TRUE"
    }
  },
  {
    "name": "Handle Finish",
    "id": "colors_handle_finish",
    "dataType": "surfaceFinish",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "handle"
    ]
  },
  {
    "name": "Stripe Size",
    "id": "colors_stripe_size",
    "dataType": "stripeSize",
    "tileType": "squareWithText",
    "defaultValueId": "1",
    "isOptional": true,
    "viewNames": [
      "batMiddle"
    ]
  },
  {
    "name": "Stripe Color",
    "id": "colors_stripe_color",
    "dataType": "stripeColor",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "batMiddle"
    ]
  },
  {
    "name": "Oval Logo",
    "id": "colors_ovalLogo",
    "dataType": "ovalLogoColor",
    "tileType": "square",
    "defaultValueId": "black",
    "isOptional": false,
    "viewNames": [
      "batMiddle"
    ],
    "availability": {
      "customAwardBat": "TRUE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "TRUE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "TRUE"
    }
  },
  {
    "name": "Middle Section",
    "id": "decoration_middleSection_type",
    "dataType": "decoType",
    "tileType": "squareWithText",
    "isOptional": true,
    "viewNames": [
      "barrelMiddle"
    ],
    "availability": {
      "customAwardBat": "customLogo, stockLogo",
      "personalizedAwardBat": "customLogo, stockLogo",
      "customMiniBat": "customLogo, stockLogo",
      "customFungoBat": "customLogo, stockLogo"
    }
  },
  {
    "name": "Custom Logo",
    "id": "decoration_middleSection_customLogo",
    "dataType": "upload",
    "tileType": "square",
    "isOptional": false,
    "extraCost": 5,
    "viewNames": [
      "barrelMiddle"
    ]
  },
  {
    "name": "Stock Logo",
    "id": "decoration_middleSection_stockLogo",
    "dataType": "stockLogo",
    "tileType": "wide",
    "tileImageProps": "stockLogos/${id}.svg",
    "isOptional": false,
    "extraCost": 5,
    "viewNames": [
      "barrelMiddle"
    ]
  },
  {
    "name": "Color",
    "id": "decoration_middleSection_color",
    "dataType": "stampColor",
    "tileType": "square",
    "tileImageProps": "colorCombo/${props.imageId}.png",
    "defaultValueId": "black",
    "isOptional": false,
    "viewNames": [
      "barrelMiddle"
    ],
    "availability": {
      "customAwardBat": "TRUE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "TRUE",
      "customGameUseBat": "FALSE",
      "customFungoBat": "TRUE"
    }
  },
  {
    "name": "End Section",
    "id": "decoration_endSection_type",
    "dataType": "decoType",
    "tileType": "squareWithText",
    "defaultValueId": "endBrandText",
    "isOptional": true,
    "viewNames": [
      "barrelEnd"
    ],
    "availability": {
      "customAwardBat": "customLogo, stockLogo, text",
      "personalizedAwardBat": "text",
      "customMiniBat": "customLogo, text",
      "customGameUseBat": "endBrandText",
      "customFungoBat": "customLogo, stockLogo, text"
    }
  },
  {
    "name": "Custom Logo",
    "id": "decoration_endSection_customLogo",
    "dataType": "upload",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "barrelEnd"
    ]
  },
  {
    "name": "Stock Logo",
    "id": "decoration_endSection_stockLogo",
    "dataType": "stockLogo",
    "tileType": "wide",
    "tileImageProps": "stockLogos/${id}.svg",
    "isOptional": false,
    "viewNames": [
      "barrelEnd"
    ],
    "availability": {
      "customAwardBat": "TRUE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "TRUE"
    }
  },
  {
    "name": "Upper Text",
    "id": "decoration_endSection_upperText",
    "dataType": "text",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "barrelEnd"
    ],
    "availability": {
      "customAwardBat": "TRUE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "TRUE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "TRUE"
    }
  },
  {
    "name": "Font",
    "id": "decoration_endSection_upperFont",
    "dataType": "font",
    "tileType": "square",
    "tileImageProps": "fonts/${id}.svg",
    "isOptional": false,
    "viewNames": [
      "barrelEnd"
    ]
  },
  {
    "name": "Middle Text",
    "id": "decoration_endSection_middleText",
    "dataType": "text",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "barrelEnd"
    ],
    "availability": {
      "customAwardBat": "TRUE",
      "personalizedAwardBat": "TRUE",
      "customMiniBat": "TRUE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "TRUE"
    }
  },
  {
    "name": "Font",
    "id": "decoration_endSection_middleFont",
    "dataType": "font",
    "tileType": "square",
    "tileImageProps": "fonts/${id}.svg",
    "isOptional": false,
    "viewNames": [
      "barrelEnd"
    ]
  },
  {
    "name": "Lower Text",
    "id": "decoration_endSection_lowerText",
    "dataType": "text",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "barrelEnd"
    ],
    "availability": {
      "customAwardBat": "TRUE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "TRUE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "TRUE"
    }
  },
  {
    "name": "Texts",
    "id": "decoration_endSection_texts",
    "dataType": "custom",
    "isOptional": true,
    "viewNames": [
      "barrelEnd"
    ],
    "availability": {
      "customAwardBat": "TRUE",
      "personalizedAwardBat": "TRUE",
      "customMiniBat": "TRUE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "TRUE"
    }
  },
  {
    "name": "Font",
    "id": "decoration_endSection_lowerFont",
    "dataType": "font",
    "tileType": "square",
    "tileImageProps": "fonts/${id}.svg",
    "isOptional": false,
    "viewNames": [
      "barrelEnd"
    ]
  },
  {
    "name": "Text",
    "id": "decoration_endSection_endBrandText",
    "dataType": "text",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "barrelEnd"
    ],
    "availability": {
      "customAwardBat": "FALSE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "FALSE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "FALSE"
    }
  },
  {
    "name": "Layout",
    "id": "decoration_endSection_endBrandLayout",
    "dataType": "text",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "barrelEnd"
    ],
    "availability": {
      "customAwardBat": "FALSE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "FALSE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "FALSE"
    }
  },
  {
    "name": "Color",
    "id": "decoration_endSection_color",
    "dataType": "stampColor",
    "tileType": "square",
    "defaultValueId": "black",
    "isOptional": false,
    "viewNames": [
      "barrelEnd"
    ],
    "availability": {
      "customAwardBat": "TRUE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "TRUE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "TRUE"
    }
  },
  {
    "name": "Knob",
    "id": "decoration_knob_type",
    "dataType": "knobDecoType",
    "tileType": "squareWithText",
    "defaultValueId": "text",
    "isOptional": false,
    "viewNames": [
      "knob"
    ],
    "availability": {
      "customAwardBat": "FALSE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "FALSE",
      "customGameUseBat": "decal",
      "customFungoBat": "FALSE"
    }
  },
  {
    "name": "Knob Decal",
    "id": "decoration_knob_decal",
    "dataType": "knobDecal",
    "tileType": "square",
    "tileImageProps": "textures/decals/${id}.png",
    "isOptional": false,
    "viewNames": [
      "knob"
    ],
    "availability": {
      "customAwardBat": "FALSE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "FALSE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "FALSE"
    }
  },
  {
    "name": "Text",
    "id": "decoration_knob_text",
    "dataType": "text",
    "tileType": "square",
    "isOptional": true,
    "viewNames": [
      "knob"
    ],
    "availability": {
      "customAwardBat": "FALSE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "FALSE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "TRUE"
    }
  },
  {
    "name": "Color",
    "id": "decoration_knob_color",
    "dataType": "knobTextColor",
    "tileType": "square",
    "defaultValueId": "black",
    "isOptional": false,
    "viewNames": [
      "knob"
    ],
    "availability": {
      "customAwardBat": "FALSE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "FALSE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "TRUE"
    }
  },
  {
    "name": "Grip Tape",
    "id": "options_gripTape",
    "dataType": "gripTape",
    "tileType": "squareWithText",
    "isOptional": true,
    "extraCost": 5,
    "viewNames": [
      "handle"
    ],
    "availability": {
      "customAwardBat": "FALSE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "FALSE",
      "customGameUseBat": "FALSE",
      "customFungoBat": "FALSE"
    }
  },
  {
    "name": "Cupped End",
    "id": "options_cuppedEnd",
    "description": "Deeper cups remove weight from the end of the bat",
    "dataType": "cuppedEnd",
    "tileType": "squareWithText",
    "defaultValueId": "no",
    "isOptional": false,
    "viewNames": [
      "end"
    ],
    "availability": {
      "customAwardBat": "TRUE",
      "personalizedAwardBat": "FALSE",
      "customMiniBat": "FALSE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "TRUE"
    }
  },
  {
    "name": "Display Tube",
    "id": "options_displayTube",
    "description": "Not visualized",
    "dataType": "yesOrNo",
    "tileType": "squareWithText",
    "defaultValueId": "no",
    "isOptional": false,
    "extraCost": 13,
    "viewNames": [
      "overview"
    ],
    "availability": {
      "customAwardBat": "TRUE",
      "personalizedAwardBat": "TRUE",
      "customMiniBat": "FALSE",
      "customGameUseBat": "FALSE",
      "customFungoBat": "FALSE"
    }
  },
  {
    "name": "Knob Style",
    "id": "options_knobStyle",
    "dataType": "knobStyle",
    "tileType": "squareWithText",
    "defaultValueId": "standard",
    "isOptional": false,
    "viewNames": [
      "knob"
    ],
    "availability": {
      "customAwardBat": "TRUE",
      "personalizedAwardBat": "TRUE",
      "customMiniBat": "TRUE",
      "customGameUseBat": "TRUE",
      "customFungoBat": "TRUE"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PropDefRow[]>> = data;

export type PropDef = typeof typed[number];

export const PROP_DEF_INDEX_KEY = "id";
export type PropDefIndexKey = "id";
export type PropDefId = PropDef["id"];

let i = 0;
export const PROP_DEF_DICT = {
  "filter_group": typed[i++],
  "filter_category": typed[i++],
  "filter_series": typed[i++],
  "product_model": typed[i++],
  "product_woodSpecies": typed[i++],
  "product_length": typed[i++],
  "product_weight": typed[i++],
  "colors_colorCombo": typed[i++],
  "colors_batColor": typed[i++],
  "colors_barrel_type": typed[i++],
  "colors_barrel_finish": typed[i++],
  "colors_handle_type": typed[i++],
  "colors_handle_finish": typed[i++],
  "colors_stripe_size": typed[i++],
  "colors_stripe_color": typed[i++],
  "colors_ovalLogo": typed[i++],
  "decoration_middleSection_type": typed[i++],
  "decoration_middleSection_customLogo": typed[i++],
  "decoration_middleSection_stockLogo": typed[i++],
  "decoration_middleSection_color": typed[i++],
  "decoration_endSection_type": typed[i++],
  "decoration_endSection_customLogo": typed[i++],
  "decoration_endSection_stockLogo": typed[i++],
  "decoration_endSection_upperText": typed[i++],
  "decoration_endSection_upperFont": typed[i++],
  "decoration_endSection_middleText": typed[i++],
  "decoration_endSection_middleFont": typed[i++],
  "decoration_endSection_lowerText": typed[i++],
  "decoration_endSection_texts": typed[i++],
  "decoration_endSection_lowerFont": typed[i++],
  "decoration_endSection_endBrandText": typed[i++],
  "decoration_endSection_endBrandLayout": typed[i++],
  "decoration_endSection_color": typed[i++],
  "decoration_knob_type": typed[i++],
  "decoration_knob_decal": typed[i++],
  "decoration_knob_text": typed[i++],
  "decoration_knob_color": typed[i++],
  "options_gripTape": typed[i++],
  "options_cuppedEnd": typed[i++],
  "options_displayTube": typed[i++],
  "options_knobStyle": typed[i++]
} as const;

export { typed as PROP_DEFS };
