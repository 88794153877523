// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ModelRow } from '../typings';

const data = [
  {
    "id": "MM13",
    "name": "MM13",
    "description": "A player designed model boasting our longest barrel length and fastest swing weight score made possible by its slender barrel diameter. A flared knob is used to provide additional comfort.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "player",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile5",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 80,
        "power": 50,
        "swingWeight": 80
      },
      "maxBarrel": 2.39,
      "minHandle": 0.95,
      "barrelLength": 11.23
    }
  },
  {
    "id": "BH3",
    "name": "BH3",
    "description": "A straight, traditional knob that graduates into a long barrel, emphasizing sweet spot size. This profile shape and slender barrel allows players to take advantage of using a dense piece of wood.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "player",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile1",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 70,
        "power": 70,
        "swingWeight": 50
      },
      "maxBarrel": 2.4,
      "minHandle": 0.93,
      "barrelLength": 9.65
    }
  },
  {
    "id": "OA1",
    "name": "OA1",
    "description": "Player model designed with a long barrel that provides forgiveness on mishits while a knob with a small flare provides hand comfort.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "player",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile2",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 80,
        "power": 50,
        "swingWeight": 60
      },
      "maxBarrel": 2.46,
      "minHandle": 0.95,
      "barrelLength": 10.01
    }
  },
  {
    "id": "110",
    "name": "110",
    "description": "A classic bat profile having a reputation for being evenly balanced. Known for having a thick taper transitioning into a medium barrel diameter.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "velo",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile4",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 80,
        "power": 60,
        "swingWeight": 50
      },
      "maxBarrel": 2.49,
      "minHandle": 0.94,
      "barrelLength": 7.7
    }
  },
  {
    "id": "243",
    "name": "243-M",
    "description": "A reduced version of a classic bat profile (243). A flared knob, thin handle, and big barrel offers an end loaded feel allowing players to drop the head through the zone.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "bigStick",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile1",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 80,
        "power": 60,
        "swingWeight": 40
      },
      "maxBarrel": 2.48,
      "minHandle": 0.9,
      "barrelLength": 9.38
    }
  },
  {
    "id": "271ML",
    "name": "271ML",
    "description": "A slight twist on the old school 271 profile, a flared knob slowly tapers into a big barrel concentrated near the end of the bat.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "velo",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile4",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 90,
        "power": 50,
        "swingWeight": 40
      },
      "maxBarrel": 2.58,
      "minHandle": 0.93,
      "barrelLength": 7.21
    }
  },
  {
    "id": "I13M",
    "name": "I13M",
    "description": "A modified version of the ever-lasting i13 designed to utilize higher density wood. Much like the original i13, the i13m is known for large barrel sizes and barrel lengths providing hitters with a big hitting area.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "bigStick",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile2",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 90,
        "power": 50,
        "swingWeight": 40
      },
      "maxBarrel": 2.51,
      "minHandle": 0.91,
      "barrelLength": 8.87
    }
  },
  {
    "id": "MT27",
    "name": "MT27",
    "description": "Player designed model with a large barrel, thin handle, and traditional knob; best suited for players that prefer an end loaded feel to their bat.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "velo",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile1",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 80,
        "power": 50,
        "swingWeight": 20
      },
      "maxBarrel": 2.48,
      "minHandle": 0.91,
      "barrelLength": 9.14
    }
  },
  {
    "id": "RA13",
    "name": "RA13",
    "description": "Player designed model that utilizes the comfort of a bell-shaped knob with a quick taper transition and long barrel. One of the most popular player profiles due to its balance of barrel size, density, and swing weight.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "bigStick",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile5",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 80,
        "power": 50,
        "swingWeight": 70
      },
      "maxBarrel": 2.44,
      "minHandle": 0.93,
      "barrelLength": 10.54
    }
  },
  {
    "id": "AP5",
    "name": "AP5",
    "description": "Player designed profile that combines a large barrel diameter with a long flat barrel resulting in our largest sweet spot score. The handle and taper of this bat have been designed to help balance out the swing weight of this bat.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "player",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile2",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 100,
        "power": 20,
        "swingWeight": 50
      },
      "maxBarrel": 2.53,
      "minHandle": 0.95,
      "barrelLength": 9.93
    }
  },
  {
    "id": "CS5",
    "name": "CS5",
    "description": "A derivative of the 271, this player profile was modified to use the densest piece of wood available by slightly reducing barrel diameter.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "bigStick",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile3",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 70,
        "power": 80,
        "swingWeight": 40
      },
      "maxBarrel": 2.47,
      "minHandle": 0.92,
      "barrelLength": 7.57
    }
  },
  {
    "id": "MT456",
    "name": "MT456",
    "description": "This profile has a straight, traditional knob that progresses into one of our largest barrels. Added mass near the point of contact results in an end loaded feel to this bat.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "player",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile1",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 90,
        "power": 40,
        "swingWeight": 20
      },
      "maxBarrel": 2.55,
      "minHandle": 0.92,
      "barrelLength": 8.67
    }
  },
  {
    "id": "151",
    "name": "151",
    "description": "Lorem ipsum dolor sit amet, ut pri viderer legimus comprehensam.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "bigStick",
      "isEnabled": false,
      "basePriceByWoodSpecies": {
        "ash": 79.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile1",
      "meshScale": 1
    }
  },
  {
    "id": "Y62",
    "name": "Y62",
    "description": "Lorem ipsum dolor sit amet, ut pri viderer legimus comprehensam.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "velo",
      "isEnabled": false,
      "basePriceByWoodSpecies": {
        "ash": 79.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile4",
      "meshScale": 1
    }
  },
  {
    "id": "customFungoBat",
    "name": "114",
    "props": {
      "categoryId": "customFungoBat",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 79.99
      },
      "viewDefGroupId": "fungo",
      "assetId": "profile6",
      "meshScale": 1
    }
  },
  {
    "id": "customMiniBat",
    "name": "MB",
    "props": {
      "categoryId": "customMiniBat",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 24.99
      },
      "viewDefGroupId": "mini",
      "assetId": "profile7",
      "meshScale": 2
    }
  },
  {
    "id": "personalizedAwardBat",
    "name": "232",
    "props": {
      "categoryId": "personalizedAwardBat",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 59.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile8",
      "meshScale": 1
    }
  },
  {
    "id": "customAwardBat",
    "name": "232",
    "props": {
      "categoryId": "customAwardBat",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 59.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile1",
      "meshScale": 1
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ModelRow[]>> = data;

export type Model = typeof typed[number];

export const MODEL_INDEX_KEY = "id";
export type ModelIndexKey = "id";
export type ModelId = Model["id"];

let i = 0;
export const MODEL_DICT = {
  "MM13": typed[i++],
  "BH3": typed[i++],
  "OA1": typed[i++],
  "110": typed[i++],
  "243": typed[i++],
  "271ML": typed[i++],
  "I13M": typed[i++],
  "MT27": typed[i++],
  "RA13": typed[i++],
  "AP5": typed[i++],
  "CS5": typed[i++],
  "MT456": typed[i++],
  "151": typed[i++],
  "Y62": typed[i++],
  "customFungoBat": typed[i++],
  "customMiniBat": typed[i++],
  "personalizedAwardBat": typed[i++],
  "customAwardBat": typed[i++]
} as const;

export { typed as MODEL };
